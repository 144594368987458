import React from 'react';
import styled from 'styled-components';
import Image from "../ui/Image/Image";

const Wrapper = styled.div`
  a {
    color: inherit;
    display: block;
    text-decoration: inherit;
  }
  
  h2 {
    margin: 0 auto 80px;
    max-width: 12em;
    font-family: ${props => props.theme.fonts.ivy};
    font-size: 34px;
    font-weight: inherit;
    line-height: 38px;
    text-align: center;
    
    @media (${props => props.theme.breakpoints.desktop}) {
      font-size: 50px;
      line-height: 50px;
    }
  }
  
  img {
    width: 100%;

    @media (${props => props.theme.breakpoints.desktop}) {
      width: auto;
    }
  }
`;

const LabelImageContainer = styled.div`
  text-align: center;
  transform: translate(0, -50%);
`;

const ImageCredit = styled.div`
  font-family: ${props => props.theme.fonts.nimbus};
  font-size: ${props => props.theme.fontSizes.body.xxs};
  color: ${props => props.theme.colors.grey};
  text-align: left;
`;

const FollowingTopic = ({content}) => {

  return (
    <Wrapper>
      <h2>{content.title}</h2>
      <a href={content.href}>
        <Image content={content.image} />
        <LabelImageContainer>
          <Image content={content.label} />
          <ImageCredit>{content.imageCredit}</ImageCredit>
        </LabelImageContainer>
      </a>
    </Wrapper>
  )
};

export default FollowingTopic;
