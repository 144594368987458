import styled from 'styled-components';

export const Title = styled.h1`
  color: ${props => props.theme.colors.dark};
  font-family: ${props => props.theme.fonts.ivy};
  font-size: 80px;
  font-weight: 300;
  line-height: 90px;
  text-align: center;
  margin: ${props => props.margin || "0"};

  @media (${props => props.theme.breakpoints.desktop}) {
    font-size: 156px;
    line-height: 187px;
  }
`;

export const HeadingOne = styled.h2`
  color: ${props => props.theme.colors.dark};
  font-family: ${props => props.theme.fonts.ivy};
  font-size: 43px;
  font-weight: 300;
  line-height: 52px;
  margin: ${props => props.margin || "0"};

  @media (${props => props.theme.breakpoints.desktop}) {
    font-size: 90px;
    line-height: 110px;
  }
`;

export const HeadingTwo = styled.h2`
  color: ${props => props.theme.colors.dark};
  font-family: ${props => props.theme.fonts.ivy};
  font-size: 36px;
  font-weight: 300;
  line-height: 40px;
  margin: ${props => props.margin || "0"};

  @media (${props => props.theme.breakpoints.desktop}) {
    font-size: 60px;
    font-weight: 300;
    line-height: 80px;
  }
`;

export const LargeBody = styled.p`
  color: ${props => props.theme.colors.dark};
  font-family: ${props => props.theme.fonts.nimbus};
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  margin: ${props => props.margin || "0"};

  @media (${props => props.theme.breakpoints.desktop}) {
    font-size: 40px;
    line-height: 50px;
  }
`;

export const SmallBody = styled.span`
  color: ${props => props.color || "#2c2c2c"};
  font-family: ${props => props.theme.fonts.nimbus};
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
  margin: ${props => props.margin || "0"};

  @media (${props => props.theme.breakpoints.desktop}) {
    
  }
`;