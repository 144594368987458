import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
  colors: {
    dark: '#292929',
    grey: '#949494',
    light: '#F8F8F8',
    blue: "#7BABFD",
    orange: "#FF553B",
    ltPurple: "#A267EC",
    dkPurple: "#5601C0",
  },
  fonts: {
    ivy: 'ivypresto-display, serif',
    nimbus: 'nimbus-sans, sans-serif',
  },
  fontSizes: {
    title: {
      xl: '150px',
      l: '70px',
      m: '60px',
      s: '50px',
    },
    body: {
      xl: '50px',
      l: '26px',
      m: '20px',
      s: '18px',
      xs: '16px',
      xxs: '12px',
    }
  },
  breakpoints: {
    desktop: `min-width: 1024px`,
    mobile: `min-width: 1024px`,
  }
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
