import React, {useRef, useEffect, useState} from 'react';
import styled from 'styled-components/macro';
import { HeadingTwo, SmallBody } from '../Type/Type';
import Image from '../ui/Image/Image';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { decoratedText } from "../Type/DecoratedText";

gsap.registerPlugin(ScrollTrigger); // Ensures tree shaking includes the plugin.

const Wrapper = styled.div`
  padding-top: 120px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media (${props => props.theme.breakpoints.desktop}) {
    padding-top: 240px;
  }
`;

const TopContainer = styled.div`
  background: ${props => props.theme.colors.light};
  margin: 0;
  max-width: 1440px;
  position: relative;
  z-index: 2;

  @media (${props => props.theme.breakpoints.desktop}) {
    margin: 0 100px;
  }
`;

const TopContent = styled.div`

  span {
    border-bottom: 1px solid ${props => props.theme.colors.dark};
    border-top: 1px solid ${props => props.theme.colors.dark};
    font-family: ${props => props.theme.fonts.nimbus};
    padding: 5px 0;
    text-transform: uppercase;
    text-align: center;
    display: block;

    @media (${props => props.theme.breakpoints.desktop}) {
      display: inline;
      border-top: none;
      text-align: left;
    }
  }

  h2 {
    margin: 36px 48px 0 48px;
    text-align: center;
    @media (${props => props.theme.breakpoints.desktop}) {
      text-align: justify;
    }
  }
`;

const Teaser = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.colors.dark};
  width: 100%;

  p {
    max-width: 389px;
    text-align: center;
    font-size: 26px;
    line-height: 30px;
    font-family: ${props => props.theme.fonts.nimbus};
    color: ${props => props.theme.colors.dark};
    margin: 0 12px;

    img {
      display: block;
      margin: 0 auto;
      margin-top: 64px;
      display: none;
    }
  }
`;

const Timeline = styled.div`
  width: 100%;
  background: ${props => props.theme.colors.dark};
  color: ${props => props.theme.colors.light};
  padding: 200vh 0 160px;
  margin: 100px 0 0;

  display: flex;
  justify-content: center;

  @media (${props => props.theme.breakpoints.desktop}) {
    padding: 200vh 0 240px;
  }
`;

const TimelineContent = styled.div`
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (${props => props.theme.breakpoints.desktop}) {
    flex-direction: row;
    margin: 0 100px;
  }
`;

const Candidates = styled.div`
  width: 100%;
  margin-bottom: 40px;

  @media (${props => props.theme.breakpoints.desktop}) {
    width: 35%;
  }

  span {
    border-top: 1px solid ${props => props.theme.colors.light};
    border-bottom: 1px solid ${props => props.theme.colors.light};
    color: ${props => props.theme.colors.light};
    padding: 5px 0;
    display: block;
    text-align: center;

    @media (${props => props.theme.breakpoints.desktop}) {
      display: inline;
      padding: 5px 0;
      text-align: left;
    }
  }

  h2 {
    color: ${props => props.theme.colors.light};
    font-family: ${props => props.theme.fonts.ivy};
    font-size: 24px;
    line-height: 36px;
    font-weight: 400;
    display: flex;
    align-items: center;
    padding: 0 6%;

    @media (${props => props.theme.breakpoints.desktop}) {
      font-size: 26px;
      line-height: 41px;
      padding: 0;
    }

    img {
      margin-left: 10px;
    }
  }
`;

const Events = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (${props => props.theme.breakpoints.desktop}) {
    width: 60%;
  }
`;

const SwoopImage = styled.div`

  img {
    max-width: 100%;
    align-self: flex-end;
    margin-right: 30%;
    width: 60px;
    position: absolute;
    bottom: -65px;
    right: 10px;

    @media (${props => props.theme.breakpoints.desktop}) {
      width: 81px;
      bottom: -90px;
    }
  }

`;

const EventContent = styled.div`
  display: block;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 60px;
  padding: 0 6%;

  @media (${props => props.theme.breakpoints.desktop}) {
    display: flex;
    padding: 0;
  }
`;

const EventTitle = styled.div`
  width: 100%;
  font-family: ${props => props.theme.fonts.ivy};
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  margin-bottom: 10px;

  @media (${props => props.theme.breakpoints.desktop}) {
    width: 30%;
  }
`;

const EventDescription = styled.div`
  width: 100%;
  font-family: ${props => props.theme.fonts.nimbus};
  font-size: 20px;
  line-height: 28px;

  @media (${props => props.theme.breakpoints.desktop}) {
    width: 60%;
    font-size: 26px;
    line-height: 37px;
  }

  sup {
    font-size: 10px;
  }

  span {
    position: relative;
    height: 27px;
    width: 31px;
    display: inline-block;

    @media (${props => props.theme.breakpoints.desktop}) {
      width: 39px;
    }
  }
  img {
    position: absolute;
    width: 100%;
    height: auto;
  }
`;

const Winner = styled.div`
  width: 100%;
  background: ${props => props.theme.colors.dark};
  color: ${props => props.theme.colors.light};
  padding: 0 0 100px;

  @media (${props => props.theme.breakpoints.desktop}) {
    width: 100px 0;
  }

  display: flex;
  justify-content: center;
`;

const WinnerContent = styled.div`
  max-width: 1440px;
  width: 90%;

  @media (${props => props.theme.breakpoints.desktop}) {
    width: 100%;
    margin: 0 100px;
  }

  h2 {
    color: ${props => props.theme.colors.light};
  }
`;

const WinnerImage = styled.div`
  display: block;
  justify-content: center;
  margin: 80px 0;
  position: relative;

  @media (${props => props.theme.breakpoints.desktop}) {
    display: flex;
  }

  img {
    max-width: 100%;
    height: auto;
  }
`;

const WinnerTape = styled.div`
  position: absolute;
  bottom: -10%;
`;

const WinnerImageCredit = styled.div`
  position: absolute;
  font-family: ${props => props.theme.fonts.nimbus};
  font-size: ${props => props.theme.fontSizes.body.xxs};
  width: 608px;
  max-width: 100%;
  bottom: -8em;
  color: ${props => props.theme.colors.grey};
`;

const Votes = ({ content }) => {

  const teaserRef = useRef();
  const timelineRef = useRef();
  const winnerImageRef = useRef();
  const tapeRef = useRef();
  const [isTapeActive, setIsTapeActive] = useState(false);

  useEffect(() => {

    gsap.fromTo(teaserRef.current, {
      background: 'radial-gradient(ellipse, rgba(248,248,248,1) 100%, rgba(41,41,41,1) 100%)',
    }, {
      background: 'radial-gradient(ellipse, rgba(248,248,248,1) 0%, rgba(41,41,41,1) 0%)',
      scrollTrigger: {
        trigger: teaserRef.current,
        start: 'center center',
        end: '+200%',
        pin: teaserRef.current,
        scrub: true,
      },
    })

    gsap.to(winnerImageRef.current, {
      transform: 'rotate(-6deg)',
      scrollTrigger: {
        trigger: winnerImageRef.current,
        start: 'top center',
        end: 'bottom',
        scrub: 1,
        onEnter: () => setIsTapeActive(true),
      }
    })
    
  }, []);

  useEffect(() => {
    setIsTapeActive(false);
  }, [content]);

  useEffect(() => {
    gsap.to(tapeRef.current ,{
      autoAlpha: isTapeActive ? 1 : 0,
      ease: 'power2.out',
    })
  }, [isTapeActive]);

  return (
    <Wrapper>

      <TopContainer>
        <TopContent>
          <span>
            {content.eyebrow}
          </span>
          <HeadingTwo>
            {decoratedText(content.title)}
          </HeadingTwo>
        </TopContent>
      </TopContainer>

      <Teaser ref={teaserRef}>
        <p>
          {decoratedText(content.leadText)}
          <Image content={content.leadArrow} />
        </p>
      </Teaser>

      <Timeline ref={timelineRef}>
        <TimelineContent>
          <Candidates>
            <SmallBody>{content.details.issue}</SmallBody>
            {content.details.candidates.map((candidate, index) =>
            <h2 key={index}>{candidate.title} <Image content={candidate.image} /></h2>
            )}
          </Candidates>
          <Events>
            {content.details.timeline.map((item, index) =>
              <EventContent key={index}>
                <EventTitle>
                  {item.when}
                </EventTitle>
                <EventDescription>
                  <div dangerouslySetInnerHTML={{ __html: item.what}}></div>
                </EventDescription>
              </EventContent>
            )}
            <SwoopImage>
              <Image content={content.swoopArrow} />
            </SwoopImage>
          </Events>
        </TimelineContent>

      </Timeline>

      <Winner>
        <WinnerContent>
          <HeadingTwo>{decoratedText(content.winnerText)}</HeadingTwo>
          <WinnerImage ref={winnerImageRef}>
            <Image content={content.winnerImage} />
            <WinnerTape ref={tapeRef}>
              <Image content={content.winnerTape} />
            </WinnerTape>
            <WinnerImageCredit>{content.winnerImageCredit}</WinnerImageCredit>
          </WinnerImage>
        </WinnerContent>
      </Winner>


    </Wrapper>
  )
}

export default Votes;