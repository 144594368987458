import getPublicPath from '../../utils/getPublicPath';

// Animations
import NativeDwellings from '../animations/NativeDwellings';

const demHead = {
  one: getPublicPath('images/climate_crisis/dem_head.png'),
  two: getPublicPath('images/climate_crisis/dem_head@2x.png'),
  a11y: 'a11y',
  loadingStyle: 'lazy',
};

const repHead = {
  one: getPublicPath('images/climate_crisis/rep_head.png'),
  two: getPublicPath('images/climate_crisis/rep_head@2x.png'),
  a11y: 'a11y',
  loadingStyle: 'lazy',
};

const climate_crisis = {
  id: 'climate_crisis',
  stats: {
    teaser: {
      year: 'EACH YEAR',
      copy: 'The U.S. emits **15%** of the world’s greenhouses gases, **20%** of which comes from drilling on our public lands.[1]',
      image: {
        one: getPublicPath('images/climate_crisis/blue_top.png'),
        two: getPublicPath('images/climate_crisis/blue_top@2x.png'),
        a11y: 'a11y',
        loadingStyle: 'lazy',
      },
    },
    animations: [
      {
        animation: NativeDwellings,
        number: 'UTAH',
        text: 'Bears Ears National Monument',
      },
    ],
    facts: {
      title: 'public land under trump',
      year: '2017-2020',
      stats: [
        {
          title: 'New Land Opened for Drilling',
          figure: '461 Million Acres[2]',
        },
        {
          title: 'Likely CO2 Impact',
          figure: '5.95 Billion Metric Tons[2]',
        },
      ],
      footer: {
        text: 'Part of the Trump administration’s efforts to lease public lands for oil and gas drilling threatened the size and protection of Bear Ears National Monument.',
        image: {
          one: getPublicPath('images/climate_crisis/arrow_stats.png'),
          two: getPublicPath('images/climate_crisis/arrow_stats@2x.png'),
          a11y: 'a11y',
          loadingStyle: 'lazy',
        },
      }  
    },   
  },
  votes: {
    eyebrow: 'Contributing to the Climate Crisis',
    title: 'In 2017, The Bureau of Land Management leased {{BlueOvalRake 76,000}} acres of recreational and sacred land near Native monuments for oil and gas drilling.[3]',
    leadText: 'Let’s look at how one local election in 2018, and just a **few votes**, made a big difference for the climate crisis.',
    leadArrow: {
      one: getPublicPath('images/racial_justice/purple_down.png'),
      two: getPublicPath('images/racial_justice/purple_down@2x.png'),
      a11y: 'a11y',
      loadingStyle: 'lazy',
    },
    details: {
      issue: 'San Juan, UT County Commission:',
      candidates: [
        {
          title: 'Kelly Laws (R)',
          image: {
            one: getPublicPath('images/climate_crisis/rep_head.png'),
            two: getPublicPath('images/climate_crisis/rep_head@2x.png'),
            a11y: 'a11y',
            loadingStyle: 'lazy',
          },
        },
        {
          title: 'vs. Willie Grayeyes (D)',
          image: {
            one: getPublicPath('images/climate_crisis/dem_head.png'),
            two: getPublicPath('images/climate_crisis/dem_head@2x.png'),
            a11y: 'a11y',
            loadingStyle: 'lazy',
          },
        },
      ],
      timeline: [
        {
          when: 'County Commission',
          what: `In San Juan county, there are 3 county commission positions that handle community issues on and near Navajo land.`,
        },
        {
          when: 'Election night results',
          what: `In 2018, Grayeyes <span><img src=${demHead.one} /></span> ran for one of those positions against Laws <span><img src=${repHead.one} /></span>. Grayeyes won with just 159 votes over Laws <span><img src=${repHead.one} /></span>.`,
        },
        {
          when: 'Court battle',
          what: `Laws <span><img src=${repHead.one} /></span> challenged Grayeyes <span><img src=${demHead.one} /></span>, an Indigenous member of the Navajo tribe, in court on his <span><img src=${demHead.one} /></span> legal residence in Utah.`,
        },
        {
          when: 'Court Ruling',
          what: `The court ruled that Grayeyes <span><img src=${demHead.one} /></span> did indeed live in Utah, not Arizona as Laws <span><img src=${repHead.one} /></span> claimed, solidifying his <span><img src=${demHead.one} /></span> spot on the San Juan County Commission.`,
        }
      ]
    },
    swoopArrow: {
      one: getPublicPath('images/climate_crisis/blue_curved_1.png'),
      two: getPublicPath('images/climate_crisis/blue_curved_1@2x.png'),
      a11y: 'a11y',
      loadingStyle: 'lazy',
    },
    winnerText: 'Winner of the court decision and {{BlueOval election}}:',
    winnerImage: {
      one: getPublicPath('images/climate_crisis/candidate.png'),
      two: getPublicPath('images/climate_crisis/candidate@2x.png'),
      a11y: 'a11y',
      loadingStyle: 'lazy',
    },
    winnerImageCredit: 'Rudi Tcruz',
    winnerTape: {
      one: getPublicPath('images/climate_crisis/candidate_tape.png'),
      two: getPublicPath('images/climate_crisis/candidate_tape@2x.png'),
      a11y: 'a11y',
      loadingStyle: 'lazy',
    },
  },
  impact: {
    blocks: [
      {
        title: 'Majority Control:',
        copy: 'In a historic win for Grayeyes, the commission was now majority Native American. Kenneth Maryboy, another Indigenous Democrat, had also won his election, and the last seat went to Republican Bruce Adams.',
        image: {
          one: getPublicPath('images/climate_crisis/blue_next_1.png'),
          two: getPublicPath('images/climate_crisis/blue_next_1@2x.png'),
          a11y: 'a11y',
          loadingStyle: 'lazy',
        },
      },
      {
        title: 'Oil Lease Rejections:',
        copy: 'In March 2019, Willie Grayeyes and Kenneth Maryboy rejected a resolution, brought forward by Adams, to support drilling on 30,300 acres of public land.',
        image: {
          one: getPublicPath('images/climate_crisis/blue_next_2.png'),
          two: getPublicPath('images/climate_crisis/blue_next_2@2x.png'),
          a11y: 'a11y',
          loadingStyle: 'lazy',
        },
      },
      {
        title: 'Non-Profit:',
        copy: 'Grayeyes and Maryboy’s rejection of the resolution, that supported drilling on culturally significant land in Utah, was celebrated by non-profits such as Southern Utah Wilderness Alliance and Friend of Cedar Mesa.',
        image: {
          one: getPublicPath('images/climate_crisis/blue_next_3.png'),
          two: getPublicPath('images/climate_crisis/blue_next_3@2x.png'),
          a11y: 'a11y',
          loadingStyle: 'lazy',
        },
      },
      {
        title: 'Court Battle:',
        copy: 'These conservation groups lobbied against the leasing of oil and gas on federal land, eventually taking the Bureau of Land Management and the Trump administration to court to stop the lease.',
        image: {
          one: getPublicPath('images/climate_crisis/blue_next_4.png'),
          two: getPublicPath('images/climate_crisis/blue_next_4@2x.png'),
          a11y: 'a11y',
          loadingStyle: 'lazy',
        },
      },
    ],
    swoopImage: {
      one: getPublicPath('images/climate_crisis/blue_next_5.png'),
      two: getPublicPath('images/climate_crisis/blue_next_5@2x.png'),
      a11y: 'a11y',
      loadingStyle: 'lazy',
    },
    image: {
      one: getPublicPath('images/climate_crisis/bears_ears.jpg'),
      two: getPublicPath('images/climate_crisis/bears_ears@2x.jpg'),
      a11y: 'a11y',
      loadingStyle: 'lazy',
    },
    imageCredit: 'Andy Mann/National Geographic Image Collection via Getty Images',
    title: 'The Bureau of Land Management {{BlueRake suspended}} leasing over 300,000 acres of land near Bears Ears National Monument, citing that the impact on {{BlueOval climate change}} and Indigenous land had not been fully addressed. This {{BlueX suspension}} helped prevent additional CO2 emissions.[4]',
    reminder: {
      title: 'Reminder:',
      copy: 'Carbon dioxide is responsible for approximately two-thirds of the additional heat trapped in the atmosphere that is causing the climate crisis.[5]',
    },
  },
  present: {
    title: 'Back to today',
    copy: [
      'The Bureau of Land Management suspended many previous leases in response to this ruling, in order to analyze the environmental impact from greenhouse-gas emissions and associated climate change impacts.',
      'In the September 2020 leasing sales, the BLM will not offer any leases near prime recreation or in San Juan county. Even though this story was a big success for both the climate and Native land, the fight is far from over.',
    ],
    images: [
      {
        one: getPublicPath('images/red_stripe.png'),
        two: getPublicPath('images/red_stripe@2x.png'),
        a11y: 'a11y',
        loadingStyle: 'lazy',
        start: 0,
        stop: 0,
        year: 'In 2020',
        text: 'Conservatives are now trying to redistrict away Navajo control.',
      },
      {
        one: getPublicPath('images/white_stripe.png'),
        two: getPublicPath('images/white_stripe@2x.png'),
        a11y: 'a11y',
        loadingStyle: 'lazy',
        start: 0,
        stop: -25,
        year: false,
        text: 'Oil and gas drilling still threatens Native land and our climate.',
      },
      {
        one: getPublicPath('images/blue_stripe.png'),
        two: getPublicPath('images/blue_stripe@2x.png'),
        a11y: 'a11y',
        loadingStyle: 'lazy',
        start: 0,
        stop: -50,
        year: 'By 2030',
        text: 'We must reduce CO2 emissions to prevent a 1.5°C warming.[6]',
      },
    ]
  },
  action: {
    title: 'Now What?',
    copy: 'The fight to stop the climate crisis is not over and it needs your help. Here are some close races coming in November 2020 where the fight for our planet and livelihood could be at stake.',
    image: {
      one: getPublicPath('images/climate_crisis/blue_now_what.png'),
      two: getPublicPath('images/climate_crisis/blue_now_what@2x.png'),
      a11y: 'a11y',
      loadingStyle: 'lazy',
    },
    ctas: [
      {
        text: 'California District 9',
        href: 'https://ballotpedia.org/California%27s_9th_Congressional_District_election,_2020',
      },
      {
        text: 'Montana Governor',
        href: 'https://ballotpedia.org/Montana_gubernatorial_and_lieutenant_gubernatorial_election,_2020',
      },
      {
        text: 'Alaska Senate',
        href: 'https://ballotpedia.org/United_States_Senate_election_in_Alaska,_2020',
      },
    ]
  },
  followingTopic: {
    title: 'See how just a few votes mattered to other issues:',
    href: '#gun_violence',
    image: {
      one: getPublicPath('images/gun_violence/footer_image.png'),
      two: getPublicPath('images/gun_violence/footer_image@2x.png'),
      a11y: '',
      loadingStyle: 'lazy',
    },
    imageCredit: 'JIM WATSON/AFP via Getty Images',
    label: {
      one: getPublicPath('images/gun_violence/title_text.png'),
      two: getPublicPath('images/gun_violence/title_text@2x.png'),
      a11y: 'Gun Violence',
      loadingStyle: 'lazy',
    },
  },
  citations: [
    {
      text: 'The Wilderness Society: Federal Lands Emissions Accountability Tool',
      href: 'https://www.wilderness.org/articles/article/federal-lands-emissions-accountability-tool',
    },
    {
      text: 'The Wilderness Society: Greenhouse Gas Emissions from Public Lands',
      href: 'https://www.wilderness.org/sites/default/files/media/file/TWS_The%20Climate%20Report%202020_Greenhouse%20Gas%20Emissions%20from%20Public%20Lands_new2.pdf',
    },
    {
      text: 'Friends of Cedar Mesa: County Commission Stands Up for Sacred Sites in Eastern San Juan County',
      href: 'https://www.friendsofcedarmesa.org/county-commission-stands-up-for-sacred-sites-in-eastern-san-juan-county/',
    },
    {
      text: 'Center for Biological Diversity: Lawsuit Spurs Trump Administration to Suspend 130 Oil, Gas Leases in Utah',
      href: 'https://biologicaldiversity.org/w/news/press-releases/lawsuit-spurs-trump-administration-suspend-130-oil-gas-leases-utah-2019-11-12/',
    },
    {
      text: 'Climate.gov: Climate Change: Atmospheric Carbon Dioxide',
      href: 'https://www.climate.gov/news-features/understanding-climate/climate-change-atmospheric-carbon-dioxide',
    },
    {
      text: 'The Intergovernmental Panel on Climate Change: Climate Change: Atmospheric Carbon Dioxide',
      href: 'https://www.ipcc.ch/site/assets/uploads/sites/2/2019/06/SR15_Headline-statements.pdf',
    },
  ]
}

export default climate_crisis;
