import React from 'react';
import styled from 'styled-components/macro';
import Image from '../ui/Image/Image';
import { Title } from '../Type/Type';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 100px 0;

  @media (${props => props.theme.breakpoints.desktop}) {
    flex-direction: row;
    padding: 200px 0;
  }
`;

const Container = styled.div`
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  @media (${props => props.theme.breakpoints.desktop}) {
    flex-direction: row;
  }
`;

const TitleContainer = styled.div`
  width: 80%;
  position: relative;

  h1 {
    text-align: center;
    margin-bottom: 42px;

    @media (${props => props.theme.breakpoints.desktop}) {
      text-align: left;
    }
  }

  img {
    position: absolute;
    top: 25px;
    left: -150px;
    width: 99px;

    @media (${props => props.theme.breakpoints.desktop}) {
      top: 50px;
      left: -220px;
      width: 183px;
    }
  }

  @media (${props => props.theme.breakpoints.desktop}) {
    width: 50%;
  }
`;
const CtaContainer = styled.div`

  width: 90%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (${props => props.theme.breakpoints.desktop}) {
    width: 40%;
    align-items: flex-start;
  }

  p {
    font-size: 20px;
    line-height: 22px;
    margin: 0 0 36px;
    font-family: ${props => props.theme.fonts.nimbus};
    text-align: center;

    @media (${props => props.theme.breakpoints.desktop}) {
      text-align: left;
    }
  }
`;
const CTA = styled.a`
  display: block;

  font-size: 16px;
  font-family: ${props => props.theme.fonts.nimbus};
  border: 1px solid ${props => props.theme.colors.dark};
  text-decoration: none;
  color: ${props => props.theme.colors.dark};
  margin: 12px 0;
  padding: 24px;
  border-radius: 35px;
  width: 100%;
  max-width: 295px;
  position: relative;
  transition: background 0.2s ease, color 0.35s linear;

  @media (${props => props.theme.breakpoints.desktop}) {
    margin: 12px 0;
    font-size: 14px;
  }

  svg {
    position: absolute;
    top: 50%;
    bottom: 50%;
    right: 7%;
    transform: translate(0, -50%);
    stroke: red;
    stroke-width: 2;
    stroke: ${props => props.theme.colors.dark};
    transition: stroke 0.2s ease;
  }

  &:hover {
    color: ${props => props.theme.colors.light};
    background: ${props => props.theme.colors.dark};

    svg {
      stroke: ${props => props.theme.colors.light};
    }
  }
`;

const Action = ({content}) => {

  return (
    <Wrapper>
      <Container>
        <TitleContainer>
          <Title>
            {content.title}
          </Title>
          <Image content={content.image} />
        </TitleContainer>
        <CtaContainer>
          <p>{content.copy}</p>
          {content.ctas.map((cta, index) =>
            <CTA key={index} href={cta.href} target="_blank" rel="noopener noreferrer">
              {cta.text}
              <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="Arrow" transform="matrix(0.70710677 -0.70710677 0.70710677 0.70710677 1.4140625 16.970703)">
                  <g id="Group" transform="translate(0.037913263 0)">
                    <path d="M0 5.16858e-17L3.72436 3.72436L18.3142 3.72436" transform="translate(0.7839457 1.2293016)" id="Path-2" fill="none" fill-rule="evenodd" />
                    <path d="M6.76055 4.91752C6.76055 4.91752 6.0579 4.92063 5.59241 4.91752C2.88341 4.89944 0.54266 2.94505 0 0" transform="translate(13.502879 0)" id="Path-3" fill="none" fill-rule="evenodd" />
                    <path d="M6.76055 4.91752C6.76055 4.91752 6.0579 4.92063 5.59241 4.91752C2.88341 4.89944 0.54266 2.94505 0 0" transform="matrix(1 0 0 -1 13.502879 9.84786)" id="Path-3" fill="none" fill-rule="evenodd" />
                  </g>
                </g>
              </svg>
            </CTA>
          )}
        </CtaContainer>
      </Container>
    </Wrapper>
  )
};

export default Action;