import React from 'react';
import styled from 'styled-components/macro';
import { HeadingTwo } from '../Type/Type';
import Image from '../ui/Image/Image';
import { decoratedText } from '../Type/DecoratedText';


const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const BlocksWrapper = styled.div`
  margin: 100px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1440px;

  @media (${props => props.theme.breakpoints.desktop}) {
    width: 90%;
    margin: 200px 0;
    border: 0;
    text-align: left;
  }
`

const BlocksTitle = styled.h2`
  font-family: ${props => props.theme.fonts.nimbus};
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  padding: 4px 0;

  border-top: 1px solid ${props => props.theme.colors.dark};
  border-bottom: 1px solid ${props => props.theme.colors.dark};

  @media (${props => props.theme.breakpoints.desktop}) {
    width: 862px;
    align-self: flex-end;
    border: 0px;
    text-align: left;
    position: relative;
    top: 25px;
  }
`;

const TopBlocks = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-wrap: wrap;
  align-self: center;
  

  @media (${props => props.theme.breakpoints.desktop}) {
    flex-direction: row;
    flex-wrap: no-wrap;
    align-self: flex-end;
  }
`;

const BottomBlocks = styled.div`
display: flex;
flex-direction: column;
position: relative;
flex-wrap: wrap;
align-self: center;


@media (${props => props.theme.breakpoints.desktop}) {
  flex-direction: row;
  flex-wrap: no-wrap;
  align-self: flex-start;
}
`;

const Line = styled.div`
  height: 1px;
  background: ${props => props.theme.colors.dark};
  position: absolute;
  width: 100%;
  display: none;
  top: 30px;

  @media (${props => props.theme.breakpoints.desktop}) {
    display: block;
  }
`

const Block = styled.div`

  width: 250px;
  padding: 30px 0;
  margin-right: 0;
  position: relative;

  @media (${props => props.theme.breakpoints.desktop}) {
    margin-right: 200px;
    width: 331px;
    padding: 60px 0;
  }

  :last-of-type {
    margin-right: 0;
  }

`;

const BlockPostMarker = styled.div`
    position: absolute;
    right: 50%;
    left: 50%;
    top: 90%;
    transform: rotate(90deg);

  @media (${props => props.theme.breakpoints.desktop}) {
    right: 0%;
    top: 50%;
    bottom: 50%;
    left: 100%;
    transform: rotate(0deg);
  }

  img {
    width: 60px;
    @media (${props => props.theme.breakpoints.desktop}) {
      width: 100px;
    }
  }
`;

const BlockSwoopMarker = styled.div`
    position: absolute;
    right: 50%;
    left: 70%;
    top: 90%;

  @media (${props => props.theme.breakpoints.desktop}) {
    right: 0%;
    top: 50%;
    bottom: 50%;
    left: 100%;
  }

  img {
    width: 60px;
  }
`;

const BlockPreMarker = styled.div`
  position: absolute;
  left: -130px;
  top: 50%;
  bottom: 50%;
  display: none;

  @media (${props => props.theme.breakpoints.desktop}) {
    display: block;
  }

  img {
    width: 50px;
    @media (${props => props.theme.breakpoints.desktop}) {
      width: 100px;
    }
  }
`;

const BlockCopy = styled.div`
  
  h1 {
    font-size: 50px;
    line-height: 55px;
    font-family: ${props => props.theme.fonts.ivy};
    font-weight: 300;
    text-align: center;

    @media (${props => props.theme.breakpoints.desktop}) {
      text-align: left;
    }
  }

  p {
    font-size: 16px;
    line-height: 22px;
    font-family: ${props => props.theme.fonts.nimbus};
    max-width: 275px;
    text-align: center;

    @media (${props => props.theme.breakpoints.desktop}) {
      text-align: left;
    }
  }

`;

const BottomContainer = styled.div`
  max-width: 1440px;
  width: 90%;
  padding: 60px 0 0;

  @media (${props => props.theme.breakpoints.desktop}) {
    padding: 120px 0 0;
  }

  h2 {
    text-align: justify;
  }

  img {
    margin-bottom: 60px;
    width: 100%;

    @media (${props => props.theme.breakpoints.desktop}) {
      margin-bottom: 120px;
    }
  }
`;

const ImageCredit = styled.div`
  font-family: ${props => props.theme.fonts.nimbus};
  font-size: ${props => props.theme.fontSizes.body.xxs};
  color: ${props => props.theme.colors.grey};

  // The Image component has a bottom margin. This is a cheap hack to avoid
  // messing with it
  position: relative;
  top: -48px;
  @media (${props => props.theme.breakpoints.desktop}) {
    top: -108px;
  }
`;

const ReminderContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Reminder = styled.div`
  width: 90%;
  max-width: 530px;
  text-align: center;
  color: ${props => props.theme.colors.light};
  background: ${props => props.theme.colors.dark};
  border-radius: 50%;
  padding: 50px;

  h1 {
    font-size: 18px;
    line-height: 22px;
    font-family: ${props => props.theme.fonts.nimbus}
    font-weight: 700;
  }

  p {
    font-size: 20px;
    line-height: 24px;
  }
`;

const Impact = ({content}) => {

  return (
    <Wrapper>
      <BlocksWrapper>
        <BlocksTitle>
          Impact
        </BlocksTitle>
        <TopBlocks>
          <Line />
          <Block>
            <BlockCopy>
              <h1>{content.blocks[0].title}</h1>
              <p>{decoratedText(content.blocks[0].copy)}</p>
            </BlockCopy>
            <BlockPostMarker>
              <Image content={content.blocks[0].image} />
            </BlockPostMarker>
          </Block>
          <Block>
            <BlockCopy>
              <h1>{content.blocks[1].title}</h1>
              <p>{decoratedText(content.blocks[1].copy)}</p>
            </BlockCopy>
            <BlockPostMarker>
              <Image content={content.blocks[1].image} />
            </BlockPostMarker>
          </Block>
        </TopBlocks>
        <BottomBlocks>
          <Line />
          <Block>
            <BlockPreMarker>
              <Image content={content.blocks[2].image} />
            </BlockPreMarker>
            <BlockCopy>
              <h1>{content.blocks[2].title}</h1>
              <p>{decoratedText(content.blocks[2].copy)}</p>
            </BlockCopy>
            <BlockPostMarker>
              <Image content={content.blocks[3].image} />
            </BlockPostMarker>
          </Block>
          <Block>
            <BlockCopy>
              <h1>{content.blocks[3].title}</h1>
              <p>{decoratedText(content.blocks[3].copy)}</p>
            </BlockCopy>
            <BlockSwoopMarker>
              <Image content={content.swoopImage} />
            </BlockSwoopMarker>
          </Block>
        </BottomBlocks>
      </BlocksWrapper>

      <BottomContainer>
        <Image content={content.image} />
        <ImageCredit>{content.imageCredit}</ImageCredit>
        <HeadingTwo>{decoratedText(content.title)}</HeadingTwo>
      </BottomContainer>

      <ReminderContainer>
        <Reminder>
          <h1>
            {content.reminder.title}
          </h1>
          <p>
            {decoratedText(content.reminder.copy)}
          </p>
        </Reminder>
      </ReminderContainer>

    </Wrapper>
  )
};

export default Impact;