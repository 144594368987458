import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.footer`
  display: block;
  background: #292929;
  color: #F8F8F8;
  font-family: ${props => props.theme.fonts.nimbus};
  font-size: 18px;
  line-height: 26px;
  padding: 48px 0;
  
  p {
    margin: 0 0 26px;
  }
  
  a {
    color: inherit;
    text-decoration: inherit;
  }
`;

const Container = styled.div`
  width: calc(100% - 96px);
  max-width: 1440px;
  margin: 0 auto;
`;

const CitationList = styled.ol`
  font-size: 12px;
  margin: 0;
  padding: 0;
  counter-reset: citations;
  list-style: none;
`;

const BULLET_WIDTH = '2.375em';
const CitationListItem = styled.li`
  counter-increment: citations;
  text-indent: -${BULLET_WIDTH};
  padding-left: ${BULLET_WIDTH};
  &::before {
    display: inline-block;
    min-width: ${BULLET_WIDTH};
    text-indent: 0;
    content: '[' counter(citations) ']';
  }
`;

const FooterCitations = (citations) => {
  return (
    <CitationList>
      {
        citations.map(
          ({text, href}) =>
            <CitationListItem key={href + ':' + text}>
              <a href={href} target="_blank" rel="noopener noreferrer">{text}</a>
            </CitationListItem>
        )
      }
    </CitationList>
  );
};

const Footer = ({ citations }) => {

  return (
  <Wrapper>
    <Container>
      <p>Your Vote Matters</p>
      {citations && citations.length > 0 ? FooterCitations(citations) : null}
    </Container>
  </Wrapper>
  )
}

export default Footer;
