import React from 'react';

const Image = ({content}) => {

  // Default to lazy loading.
  const loadingStyle = content.loadStyle || 'lazy';

  if (content.svg) {
    return (
      <img src={content.svg.url} alt={content.a11y} />
    )
  } else {
    return (
      <img 
        loading={loadingStyle}
        srcSet={content.one + ' 1x, ' + content.two + ' 2x'}
        sizes="(max-width: 600px) 480px, 800px, 1024px"
        src={content.one}
        alt={content.a11y} />
    )
  }
}

export default Image;
