import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import getPublicPath from '../utils/getPublicPath';
import gsap from 'gsap';

const video_desktop = getPublicPath('videos/intro_desktop--small.mp4');
const video_mobile = getPublicPath('videos/intro_mobile--small.mp4');

const Wrapper = styled.div`
  position: fixed;
  top: 0; 
  right: 0; 
  bottom: 0; 
  left: 0;
  overflow: hidden;
  z-index: 9999;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;

    @media (min-aspect-ratio: 16/9) {
      height: 300%; top: -100%;
    }

    @media (max-aspect-ratio: 16/9) {
      width: 300%; left: -100%;
    }

    @supports (object-fit: cover) {
      top: 0; left: 0;
      width: 100%; height: 100%;
      object-fit: cover;
    }
  }
`;

const Intro = ({}) => {

  const [active, setActive] = useState(true);
  const [videoEnded, setVideoEnded] = useState(false);
  const [ loaded ] = useState(localStorage.getItem('intro_loaded'));
  const [video, setVideo] = useState();
  const [fadeOutTween, setFadeOutTween] = useState();
  const wrapperRef = useRef(null);
  const mql = window.matchMedia('(orientation:portrait)');

  const onVideoEnd = (e) => {
    setVideoEnded(true);
  }

  const onAnimationComplete = () => {
    localStorage.setItem('intro_loaded', true);
    setActive(false);
  }

  const portraitTest = ({matches}) => {
    // Toggle the video src if the orientation changes
    if (matches) {
      setVideo(video_mobile)
    } else {
      setVideo(video_desktop)
    }
  }

  useEffect(() => {
    if(videoEnded) {
      fadeOutTween.play();
    }
  }, [videoEnded]);

  useEffect(() => {
    if ( localStorage.getItem('intro_loaded') ) {
      setActive(false)
    } else {
      // Media query listener
      mql.addEventListener('change', portraitTest);
      portraitTest(mql);

      //Tween
      const tween = gsap.to(wrapperRef.current, {
        opacity: 0,
        duration: 0.5,
        ease: 'power2.inOut',
        paused: true,
        onComplete: () => onAnimationComplete(),
      });

      setFadeOutTween(tween);
    }
  }, []);

  if (active) {
    return (
      <Wrapper ref={wrapperRef}>
        <video
          autoPlay
          playsInline
          muted
          onEnded={onVideoEnd}
          src={video}
        />
      </Wrapper>
    )
  } else {
    return (
      <></>
    )
  }
}

export default Intro;