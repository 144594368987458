import React from 'react';
import styled from 'styled-components/macro';
import { HeadingOne, HeadingTwo, LargeBody } from '../Type/Type';
import getPublicPath from '../utils/getPublicPath';
import Image from '../ui/Image/Image';
import { decoratedText } from '../Type/DecoratedText';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0;
  background: url(${getPublicPath('images/bg_texture.png')}) repeat;
  position: relative;
  flex-direction: column;
  align-items: center;

  @media (${props => props.theme.breakpoints.desktop}) {
    padding: 300px 0;
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 50px 0 150px 0;

  @media (${props => props.theme.breakpoints.desktop}) {
    position: absolute;
    top: 30px;
    left: 30px;
    padding: 0;
  }
`;

const Container = styled.div`
  max-width: 90%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 2;

  @media (${props => props.theme.breakpoints.desktop}) {
    max-width: 1440px;
  }
`

const Top = styled.div`
  max-width: 1045px;

  h2 {
    margin: 0 0 40px 0;
  }

  h2, p {
    text-align: center;
    
    @media (${props => props.theme.breakpoints.desktop}) {
      text-align: left;
    }
  }
`

const Middle = styled.div`
  max-width: 1045px;

  h2 {
    margin: 150px 0 20px 0;

    @media (${props => props.theme.breakpoints.desktop}) {
      margin: 300px 0 40px 0;
    }
  }

  h2, p {
    text-align: center;
    
    @media (${props => props.theme.breakpoints.desktop}) {
      text-align: left;
    }
  }
`

const Bottom = styled.div`
  max-width: 1045px;
  margin-top: 150px;

  @media (${props => props.theme.breakpoints.desktop}) {
    margin-top: 300px;
  }

  h2, p {
    text-align: center;
    
    @media (${props => props.theme.breakpoints.desktop}) {
      text-align: left;
    }
  }
`

const Offset = styled.div`
  padding-left: 0;
  padding-right: 0;

  @media (${props => props.theme.breakpoints.desktop}) {
    padding-left: 96px;
    padding-right: 39px;
  }
`;

const Fade = styled.div`
  background-image: linear-gradient(to bottom, rgba(248, 248, 248, 0), rgba(248, 248, 248, 1));
  height: 430px;
  width: 100%;
  position: absolute;
  bottom: 0;
`;

const Content = {
  logo: {
    one: getPublicPath('images/logo.png'),
    two: getPublicPath('images/logo@2x.png'),
    a11y: 'Your Vote Matters logo',
    loadingStyle: 'eager',
  },
  top: {
    heading: 'What if we could show you that just one vote—{{RedLowSolid like yours}}—actually matters?',
    body: 'We get it, you’re skeptical. It’s easy to think that one voice can’t make a difference among millions, that the system’s rigged, that your vote doesn’t matter.',
  },
  middle: {
    heading: 'But history tells a different story: sometimes just {{RedOval a few votes}} makes all the difference.',
    body: 'From climate change, gun violence, or racial justice, elections have real impact.',
  },
  bottom: {
    heading: 'So decide for yourself, see how the issues you care about are at stake {{RedLowRake every}} election.'
  }
}

const Hero = () => {

  return (
    <Wrapper>
      <Logo>
        <Image content={Content.logo} />
      </Logo>
      <Container>
        <Top>
          <HeadingOne>
            {decoratedText(Content.top.heading)}
          </HeadingOne>
          <Offset>
            <LargeBody>
              {Content.top.body}
            </LargeBody>
          </Offset>
        </Top>
        <Middle>
          <HeadingTwo>
            {decoratedText(Content.middle.heading)}
          </HeadingTwo>
          <Offset>
            <LargeBody>
              {Content.middle.body}
            </LargeBody>
          </Offset>
        </Middle>
        <Bottom>
          <HeadingTwo>
            {decoratedText(Content.bottom.heading)}
          </HeadingTwo>
        </Bottom>
      </Container>
      <Fade />
    </Wrapper>
  )
}

export default Hero;