import React, {useRef, useEffect} from 'react';
import styled from 'styled-components';
import { Title } from '../Type/Type';
import Image from '../ui/Image/Image';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { decoratedText } from "../Type/DecoratedText";

gsap.registerPlugin(ScrollTrigger); // Ensures tree shaking includes the plugin.

const Wrapper = styled.div`
  padding: 120px 0;
  background: ${props => props.theme.colors.dark};
  width: 100%;
  overflow: hidden;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  img {
    width: 150%;
    position: relative;
    left: -25%;

    @media (${props => props.theme.breakpoints.desktop}) {
      width: 100%;
      left: 0;
    }
  }

  h1, span {
    color: ${props => props.theme.colors.light};
  }

  .parallax {
    max-width: 1440px;
    position: relative;
    margin-bottom: 30px;

    @media (${props => props.theme.breakpoints.desktop}) {
      margin-bottom: 60px;
    }

    &:nth-child(1) {
      transform: rotate(-4deg);
      z-index: 3;
    }
    &:nth-child(2) {
      transform: rotate(2deg);
      z-index: 2;
    }
    &:nth-child(3) {
      transform: rotate(-4deg);
    }
  }
`;

const ParallaxContainer = styled.div`

  &:nth-child(1) {
    transform: rotate(-4deg);
  }
`;

const ParallaxCopy = styled.div`
  color: ${props => props.theme.colors.dark};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: ${props => props.theme.fonts.nimbus};
  font-size: 18px;
  width: 95%;
  text-align: center;
  align-items: center;

  display: flex;
  flex-direction: row;

  @media (${props => props.theme.breakpoints.desktop}) {
    font-size: 50px;
  }

  p {
    margin: 0;
    padding: 0;
    width: 88%;
    @media (${props => props.theme.breakpoints.desktop}) {
      width: 93%;
    }
  }

  span {
    color: ${props => props.theme.colors.dark};
    border: 1px solid ${props => props.theme.colors.dark};
    padding: 10px 25px;
    border-radius: 50%;
    font-family: ${props => props.theme.fonts.ivy};
    font-size: 10px;
    font-style: italic;
    font-weight: 300;
    margin-right: 20px;
    display: ${props => props.year ? 'inline' : 'none'};
    width: 13%;

    @media (${props => props.theme.breakpoints.desktop}) {
      font-size: 20px;
      width: 8%;
    }
  }
`

const TopContainer = styled.div`
  max-width: 1440px;
  padding-bottom: 240px;
  width: 90%;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (${props => props.theme.breakpoints.desktop}) {
    align-items: flex-start;
  }

  h1 {
    text-align: center;
    margin-bottom: 120px;
    text-transform: uppercase;
    width: 100%;
  }
`;

const CopyContainer = styled.div`
  max-width: 600px;
  border-top: 1px solid ${props => props.theme.colors.light};
  border-bottom: 1px solid ${props => props.theme.colors.light};

  p {
    font-size: 20px;
    line-height: 22px;
    font-family: ${props => props.theme.fonts.nimbus};
    color: ${props => props.theme.colors.light};
    width: 100%;
    text-align: center;

    @media (${props => props.theme.breakpoints.desktop}) {
      text-align: left;
    }
  }
`;

const Present = ({content}) => {

  const parallaxRefs = useRef([]);
  parallaxRefs.current = [];

  const addToRefs = el => {
    if (el && !parallaxRefs.current.includes(el)) {
      parallaxRefs.current.push(el);
    }
  }

  useEffect(() => {

    parallaxRefs.current.forEach((el) => {
      gsap.fromTo(el, {
        y: `${el.attributes.start.value}px`,
      }, {
        y: `${el.attributes.stop.value}px`,
        scrollTrigger: {
          trigger: el,
          start: 'top bottom',
          toggleActions: 'play none none reverse',
          scrub: true,
        }
      })

    });

  }, [])

  return (
    <Wrapper>
      <TopContainer>
        <Title>
          {content.title}
        </Title>
        <CopyContainer>
          {content.copy.map((item, index) =>
            <p key={index}>
              {item}
            </p>
          )}
        </CopyContainer>
      </TopContainer>
      <ParallaxContainer>
        {content.images.map((image, index) =>
          <div className="parallax" key={index} ref={addToRefs} start={image.start} stop={image.stop}>
            <Image content={image} />
            <ParallaxCopy year={image.year}>
              <span>{image.year}</span><p>{decoratedText(image.text)}</p>
            </ParallaxCopy>
          </div>
        )}
      </ParallaxContainer>
    </Wrapper>
  )

}

export default Present;