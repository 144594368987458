import React, { useState } from 'react';
import {
  Intro,
  Hero,
  Topics,
  Footer,
} from './components/components.js';
import Theme from "./Theme";

function App() {
  const [content, setContent] = useState(null);

  return (
    <Theme>
      <Intro />
      <Hero />
      <Topics onContentChange={setContent} />
      <Footer citations={content && content.citations} />
    </Theme>
  );
}

export default App;
