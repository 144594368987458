import React from 'react';
import Stats from './Stats';
import Votes from './Votes';
import Impact from './Impact';
import Present from './Present';
import Action from './Action';
import FollowingTopic from './FollowingTopic';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Topic = ({ content }) => {
  return (
    <Wrapper>
      <Stats content={content.stats} />
      <Votes content={content.votes} />
      <Impact content={content.impact} />
      <Present content={content.present} />
      <Action content={content.action} />
      <FollowingTopic content={content.followingTopic} />
    </Wrapper>
  )
};

export default Topic