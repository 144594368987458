import React from 'react';
import styled from 'styled-components';
import getPublicPath from '../utils/getPublicPath';

const DECORATION_PATH_PREFIX = 'images/dec';

function decorationImagePath(name) {
  return getPublicPath(`${DECORATION_PATH_PREFIX}/${name}.png`);
}

function decUrl(name) {
  return `url(${decorationImagePath(name)})`;
}

// This was initially a span, but there are a few span descendant selectors in
// play in other components. You could argue that <em> is appropriate - these
// decorations emphasize text.
const DecorationWrapper = styled.em`
  display: inline-block;
  position: relative;
  font-style: inherit;
  z-index: 1;
`;

const Decoration = styled.div`
  position: absolute;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: -1;
`;

function makeDecorationGeometry(left, top, right, bottom) {
  return styled(Decoration)`
    left: ${left};
    top: ${top};
    right: ${right};
    bottom: ${bottom};
  `;
}

function makeDecoration(assetName, geometry) {
  return styled(geometry)`
    background-image: ${decUrl(assetName)};
  `;
}

const LowSolid = makeDecorationGeometry('-.5em',     '.25em',   '-.25em',   '-.25em');
const Oval =     makeDecorationGeometry('-.9375em', '-.375em',  '-.3125em', '-.375em');
const Rake =     makeDecorationGeometry('-.5em',     '.25em',   '-.25em',   '0');
const LowRake =  makeDecorationGeometry('-.75em',    '.6875em', '-.4375em', '-.5em');
const OvalRake = makeDecorationGeometry('-.4375em',  '.25em',   '-.25em',   '-.1875em');
const Single =   makeDecorationGeometry('-.5em',     '.75em',   '-.5em',    '-.25em');
const X =        makeDecorationGeometry('-.5em',    '-.75em',   '-.375em',  '-.75em');

const RedLowSolid = makeDecoration('red-solid',     LowSolid);
const RedOval =     makeDecoration('red-oval',      Oval);
const RedRake =     makeDecoration('red-rake',      Rake);
const RedLowRake =  makeDecoration('red-rake',      LowRake);
const RedOvalRake = makeDecoration('red-oval-rake', OvalRake);
const RedSingle =   makeDecoration('red-single',    Single);
const RedX =        makeDecoration('red-x',         X);

const BlueLowSolid = makeDecoration('blue-solid',     LowSolid);
const BlueOval =     makeDecoration('blue-oval',      Oval);
const BlueRake =     makeDecoration('blue-rake',      Rake);
const BlueLowRake =  makeDecoration('blue-rake',      LowRake);
const BlueOvalRake = makeDecoration('blue-oval-rake', OvalRake);
const BlueSingle =   makeDecoration('blue-single',    Single);
const BlueX =        makeDecoration('blue-x',         X);

const PurpleLowSolid = makeDecoration('purple-solid',     LowSolid);
const PurpleOval =     makeDecoration('purple-oval',      Oval);
const PurpleRake =     makeDecoration('purple-rake',      Rake);
const PurpleLowRake =  makeDecoration('purple-rake',      LowRake);
const PurpleOvalRake = makeDecoration('purple-oval-rake', OvalRake);
const PurpleSingle =   makeDecoration('purple-single',    Single);
const PurpleX =        makeDecoration('purple-x',         X);

const DECORATIONS = Object.assign(Object.create(null), {
  RedLowSolid,
  RedOval,
  RedRake,
  RedLowRake,
  RedOvalRake,
  RedSingle,
  RedX,

  BlueLowSolid,
  BlueOval,
  BlueRake,
  BlueLowRake,
  BlueOvalRake,
  BlueSingle,
  BlueX,

  PurpleLowSolid,
  PurpleOval,
  PurpleRake,
  PurpleLowRake,
  PurpleOvalRake,
  PurpleSingle,
  PurpleX,
});

function decorateRun(decorationName, text) {
  const DecorationClass = DECORATIONS[decorationName];
  if (!DecorationClass) {
    console.warn(`Unknown decoration name: ${decorationName}`);
    return text;
  }

  return (
    <DecorationWrapper key={text}>
      <DecorationClass />
      {text}
    </DecorationWrapper>
  );
}

// It's unfortunate we need to use two elements to do a superscript but:
// - In the design, superscripts are a fixed 10px regardless of the context
//   font size.
// - By default, both the size and vertical offset of a <sup> are expressed as a
//   factor of the inherited font size.
// - Setting a fixed 10px font size means we can't use the em size of the parent font
//   to control its position.
// - So, we use the outer element to create a vertical offset in terms of the
//   parent font size.
// - And use the inner element to set the fixed font size.

const CitationPosition = styled.sup`
  vertical-align: inherit;
  position: relative;
  font-size: 1em;
  top: -1ex;

  // give it a little nudge to the right; it can collide if the parent font is
  // large and italic
  left: 0.125em;
`;

const CitationStyle = styled.cite`
  font-family: ${props => props.theme.fonts.nimbus};
  font-size: 10px;
  font-style: normal;
`;

const Emphasis = styled.strong`
  font-family: ${props => props.theme.fonts.ivy};
  font-size: ${30 / 26}em;
  font-style: italic;
  font-weight: bolder;
`;

const DECORATED_TEXT = /\x7B{2}\s*(\S+)\s+([^\x7D]+)\x7D{2}/g;
const CITATION = /\x5B[,\x2D\d\x5B\x5D]+\x5D/g;
const STRONG = /\x2A{2}([^\x2A]+)\x2A{2}/g;

const TOKENS = new RegExp(
  `(${DECORATED_TEXT.source})` +
  `|(${CITATION.source})` +
  `|(${STRONG.source})`,
  'g'
);

export function decoratedText(text) {
  const elements = [];
  let nextIndex = 0;
  let key = 0;

  for (let token of text.matchAll(TOKENS)) {
    // debugger;
    if (token.index !== nextIndex) {
      elements.push(text.slice(nextIndex, token.index));
    }
    nextIndex = token.index + token[0].length;

    if (token[1]) {
      // groups 1-3: Text decoration syntax: {{ComponentName text}}
      elements.push(decorateRun(token[2], token[3]));
    } else if (token[4]) {
      // group 4: Footnote syntax: [1] etc, but also supports ranges e.g.
      //          [4-13] and adjacent closed ranges separated by commas e.g.
      //          [4-13],[14]
      elements.push(
        <CitationPosition key={key++}>
          <CitationStyle>{token[4]}</CitationStyle>
        </CitationPosition>
      );
    } else if (token[5]) {
      // group 5-6: Markdown-esque emphasis syntax; surrounding text with
      //            double-asterisks
      elements.push(<Emphasis key={key++}>{token[6]}</Emphasis>)
    }
  }

  if (nextIndex < text.length) {
    elements.push(text.slice(nextIndex));
  }

  return elements;
}
