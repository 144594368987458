import React, {useRef, useEffect, useState} from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import Image from '../ui/Image/Image';
import getPublicPath from '../utils/getPublicPath';
import Topic from './Topic';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

import gun_violence from './content/gun_violence';
import racial_justice from './content/racial_justice';
import climate_crisis from './content/climate_crisis';


gsap.registerPlugin(ScrollTrigger, ScrollToPlugin); // Ensures tree shaking includes the plugin.


const ACTIVE_ITEM_TWEEN_TIME = 0.75;
const INACTIVE_ITEM_TWEEN_TIME = 0.9;
const ITEM_TWEEN_TIME = Math.max(ACTIVE_ITEM_TWEEN_TIME, INACTIVE_ITEM_TWEEN_TIME);
const RETURN_FADE_TIME = 0.375;
const SCRUB = 0.25; // `true` or time in seconds

const SCRUB_MSEC = typeof SCRUB === 'number' ? SCRUB * 1000 : 0;

const Wrapper = styled.div`
  max-width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Menu = styled.menu`
  position: relative;
  margin: 0; padding: 0;
  width: 100%;
`

const MenuContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  height: 100vh;
`;

const MenuItem = styled.div`
  width: 33%;
  height: 100%;
  margin: ${props => props.active ? '0 30px' : '0'};
  transform: ${props => props.active ? 'scale(1.1)' : 'scale(1)'};
  position: absolute;
  top: 50%;  
  left: 50%;
  transform: translate(-50%, -50%);
  height: fit-content;

  img {
    width: 100%;
    height: auto;
  }
`;

const MenuItemContent = styled.div`
  position: relative;
`;

const MenuTape = styled.div`
  width: 100%;

  img {
    position: absolute;
    bottom: 10px;
    left: 50%;
    width: 130%;
    transform: translate(-50%, 0);

    @media (${props => props.theme.breakpoints.desktop}) {
      padding-top: 30px;
    }
  }
`;

const ImageCredit = styled.div`
  font-family: ${props => props.theme.fonts.nimbus};
  font-size: ${props => props.theme.fontSizes.body.xxs};
  color: ${props => props.theme.colors.grey};
`;

const StepButton = styled.button`
  display: block;
  position: absolute;
  top: 0;
  width: 33%;
  height: 100%;
  z-index: 10;

  appearance: none;
  background: transparent;
  margin: 0;
  border: 0;
  padding: 0;
  
  &:focus {
    outline: none;
  }
`;

const Next = styled(StepButton)`
  right: 0;
  cursor: url(${getPublicPath('images/arrow_black_right.png')}) 31 21, pointer;
`;

const Prev = styled(StepButton)`
  left: 0;
  cursor: url(${getPublicPath('images/arrow_black_left.png')}) 31 21, pointer;
`;

const MenuContent = [
  {
    id: 'climate_crisis',
    content: climate_crisis,
    image: {
      one: getPublicPath('images/climate_crisis/menu_image.png'),
      two: getPublicPath('images/climate_crisis/menu_image@2x.png'),
      a11y: 'a11y',
      loadingStyle: 'lazy',
    },
    imageCredit: 'MARK RALSTON/AFP via Getty Images',
    tape: {
      one: getPublicPath('images/climate_crisis/title_text.png'),
      two: getPublicPath('images/climate_crisis/title_text@2x.png'),
      a11y: 'a11y',
      loadingStyle: 'lazy',
    }
  },
  {
    id: 'gun_violence',
    content: gun_violence,
    image: {
      one: getPublicPath('images/gun_violence/menu_image.png'),
      two: getPublicPath('images/gun_violence/menu_image@2x.png'),
      a11y: 'a11y',
      loadingStyle: 'lazy',
    },
    imageCredit: 'JIM WATSON/AFP via Getty Images',
    tape: {
      one: getPublicPath('images/gun_violence/title_text.png'),
      two: getPublicPath('images/gun_violence/title_text@2x.png'),
      a11y: 'a11y',
      loadingStyle: 'lazy',
    }
  },
  {
    id: 'racial_justice',
    content: racial_justice,
    image: {
      one: getPublicPath('images/racial_justice/menu_image.png'),
      two: getPublicPath('images/racial_justice/menu_image@2x.png'),
      a11y: 'a11y',
      loadingStyle: 'lazy',
    },
    imageCredit: 'Daniel Lean-Olivas/AFP via Getty Images',
    // original imageCredit: 'Rob Liggins',
    tape: {
      one: getPublicPath('images/racial_justice/title_text.png'),
      two: getPublicPath('images/racial_justice/title_text@2x.png'),
      a11y: 'a11y',
      loadingStyle: 'lazy',
    }
  },
]

function menuIndexById(id) {
  return MenuContent.findIndex((article) => article.id === id);
}

function menuIndexByHash(hash) {
  // empty hash -> return the default
  if (!hash || hash === '#') return 1;

  let i = 0;
  if (hash) {
    if (hash[0] === '#') i++;
    const id = hash.slice(i);
    return menuIndexById(id);
  }

  return -1;
}

function isRectInViewport(rect) {
  return (
    rect.right > rect.left &&
    rect.bottom > rect.top &&
    rect.bottom >= 0 &&
    rect.top <= window.innerHeight
  );
}

function isElementInViewport(el) {
  return isRectInViewport(el.getBoundingClientRect());
}

const TAPE_STATE_OFF = {
  opacity: 0,
  y: 80,
  scale: 1.25,
  rotate: 20,
};

const TAPE_STATE_ON = {
  opacity: 1,
  y: 0,
  scale: 1,
  rotate: 0,
};

const Topics = ({ onContentChange }) => {
  let initialIndex = menuIndexByHash(window.location.hash);
  if (initialIndex < 0) initialIndex = 1;

  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [content, setContent] = useState(MenuContent[currentIndex].content);

  const wrapperRef = useRef(null);
  const containerRef = useRef(null);
  const menuRefsById = useRef({});
  const scrollsById = useRef({});
  const tweensById = useRef({});

  const setCurrentIndexWithTransition = (newIndex) => {
    if (newIndex < 0 || newIndex >= MenuContent.length) {
      return;
    }

    if (!wrapperRef.current || !containerRef.current) {
      // we don't have references for visibility check; just set and exit
      setCurrentIndex(newIndex);
      return;
    }

    if (isElementInViewport(containerRef.current)) {
      // tween scroll
      const containerRect = containerRef.current.getBoundingClientRect();

      // adjust the duration based on how far we have to scroll. the closer
      // we are, the less time we take.
      const duration = Math.min(1, Math.abs(containerRect.top) / 1080);

      gsap.to(window, {
        scrollTo: Math.round(window.scrollY + containerRect.top),
        duration,
        ease: 'power2.inOut',
        onComplete: () => setCurrentIndex(newIndex),
      });
    } else {
      // fade out, instant scroll, fade in
      gsap.to(wrapperRef.current, {
        opacity: 0,
        duration: RETURN_FADE_TIME,
        onComplete: () => {
          if (containerRef.current) {
            const containerRect = containerRef.current.getBoundingClientRect();
            window.scrollTo(0, Math.round(window.scrollY + containerRect.top));
          }

          // give the scrub option some time to settle
          setTimeout(
            () => setCurrentIndex(newIndex),
            SCRUB_MSEC + 40
          );

          // wait for the carousel tween to complete, then fade it back in
          setTimeout(
            () => {
              gsap.to(wrapperRef.current, {
                opacity: 1,
                duration: RETURN_FADE_TIME,
              });
            },
            ITEM_TWEEN_TIME * 1000 // gsap deals in seconds, setTimeout wants msec
          );
        }
      });
    }
  };

  useEffect(() => {
    for (let [key, groupRef] of Object.entries(menuRefsById.current)) {
      if (key.slice(0, 2) !== 'g_') {
        continue;
      }

      const id = key.slice(2);
      const creditRef = menuRefsById.current['c_' + id];

      // Create a group of two animations that will be driven by the scroll:
      // - Rotation and displacement of the image
      // - A very quick fade in of the image credit, soon after (but not at)
      //   the start of the tween
      const animation = gsap.timeline()
        .fromTo(
          groupRef,
          {
            rotation: 0,
            y: '0%',
          },
          {
            rotation: -5,
            y: '150%',
            ease: 'sine.inOut',
            duration: 1,
          }
        )
        .fromTo(
          creditRef,
          {
            opacity: 0
          },
          {
            opacity: 1,
            duration: 0.1
          },
          0.15
        );

      const scrollTrigger = ScrollTrigger.create({
        trigger: groupRef,
        id,
        animation: animation,
        start: 'center 45%',
        end: 'center top-=55%',
        scrub: 0.25,
      });

      scrollTrigger.disable();
      scrollsById.current[id] = {id, animation, scrollTrigger};
    }

    // cleanup
    return () => {
      for (let [id, scrollMemo] of Object.entries(scrollsById.current)) {
        scrollMemo.scrollTrigger.kill();
        delete scrollsById.current[id];
      }
    }
  }, []); // no dependencies: run only at mount and unmount

  const storeMenuItemRef = el => {
    if (el) {
      menuRefsById.current[el.id] = el;
    }
  };

  useEffect(() => {
    const onHashChange = (event) => {
      event.preventDefault();
      const index = menuIndexByHash(window.location.hash);
      if (index >= 0) setCurrentIndexWithTransition(index);
      return false;
    };

    window.addEventListener('hashchange', onHashChange);

    // cleanup
    return () => window.removeEventListener('hashchange', onHashChange);
  }, []);

  useEffect(() => {
    if (onContentChange) onContentChange(content);
  }, [content]);

  useEffect(() => {
    // For each MenuContent item, find the corresponding ref and tell it
    // where it needs to go. Also find its ScrollTrigger and enable it if it's
    // the active one, else disable it.
    for (let i = 0; i < MenuContent.length; i++) {
      const data = MenuContent[i];

      const groupRef = menuRefsById.current['g_' + data.id];
      const tapeRef = menuRefsById.current['t_' + data.id];

      const {scrollTrigger} = scrollsById.current[data.id];

      // slot works out to be a number from 0-2, nominating where the element
      // needs to appear.
      // - 0 is left
      // - 1 is center, where MenuContent[currentIndex] appears
      // - 2 is right
      const slot = (i - currentIndex + 1 + MenuContent.length) % MenuContent.length;

      let groupTweenTarget;
      let tapeTweenTarget;
      let zIndex = 2;

      if (slot === 1) {
        scrollTrigger.enable();
        zIndex++;
        groupTweenTarget = {
          left: '50%',
          y: 0,
          scale: 1.125,
          duration: ACTIVE_ITEM_TWEEN_TIME,
        };

        tapeTweenTarget = Object.assign({
          delay: ACTIVE_ITEM_TWEEN_TIME,
        }, TAPE_STATE_ON);
      } else {
        scrollTrigger.disable();
        groupTweenTarget = {
          left: slot === 0 ? '10%' : '90%', // TODO: on mobile, make this '0%' or '100%'
          scale: 1,
          duration: INACTIVE_ITEM_TWEEN_TIME,
        }

        // Find out the last thing we did with this ref. If the most recently
        // tweened scale value is equal to the new scale value, that means it
        // is wrapping around from the left edge to the right edge or vice
        // versa. In which case, give it the lowest zIndex.
        if (tweensById.current[data.id]?.vars?.scale === groupTweenTarget.scale) {
          zIndex--;
        }

        tapeTweenTarget = Object.assign({}, TAPE_STATE_OFF);
      }

      groupTweenTarget.ease = 'power2.inOut';
      tapeTweenTarget.ease = 'none';
      tapeTweenTarget.duration = groupTweenTarget.duration * 0.25

      gsap.set(groupRef, {zIndex});
      tweensById.current[data.id] = gsap.to(groupRef, groupTweenTarget);

      gsap.to(tapeRef, tapeTweenTarget);
    }

    setContent(MenuContent[currentIndex].content);
  }, [currentIndex]);

  const stepTopic = (delta) => {
    const newIndex = (currentIndex + delta + MenuContent.length) % MenuContent.length;
    const newTopic = MenuContent[newIndex];
    if (newTopic) {
      window.location = `#${newTopic.id}`;
    }
  };

  const handleNext = () => {
    stepTopic(1);
  };

  const handlePrev = () => {
    stepTopic(-1);
  };

  return (
    <Wrapper ref={wrapperRef}>
      <Menu>
        <MenuContainer ref={containerRef}>
          {MenuContent.map((item) =>
            <MenuItem key={item.id} id={'g_' + item.id} ref={storeMenuItemRef}>
              <MenuItemContent>
                <Image content={item.image} />
                <MenuTape id={'t_' + item.id} ref={storeMenuItemRef}>
                  <Image content={item.tape} />
                </MenuTape>
                <ImageCredit id={'c_' + item.id} ref={storeMenuItemRef}>
                  {item.imageCredit}
                </ImageCredit>
              </MenuItemContent>
            </MenuItem>
          )}
        </MenuContainer>
        <Next onClick={handleNext} />
        <Prev onClick={handlePrev} />
      </Menu>
      <Topic content={content} />
    </Wrapper>
  )
}

export default Topics;
