import getPublicPath from '../../utils/getPublicPath';

import animRyanCox from '../animations/P07_RyanCox.json';
import animTaraGallagher from '../animations/P08_TaraG.json';
import animLaquitaBrown from '../animations/P09_LaquitaB.json';
import animMichelleLanger from '../animations/P10_MichelleL.json';
import animAlexanderGusev from '../animations/P11_AlexanderG.json';
import animBobbyWilliams from '../animations/P12_BobbyW.json';
import animChristopherRapp from '../animations/P01_ChristopherR.json';
import animHerbertSnelling from '../animations/P02_HerbertS.json';
import animJoshuaHardy from '../animations/P03_JoshuaH.json';
import animKatherineNixon from '../animations/P04_KatherineN.json';
import animMaryLouiseGayle from '../animations/P05_MaryLGayle.json';
import animRichardNettleton from '../animations/P06_RichardN.json';

const demHead = {
  one: getPublicPath('images/gun_violence/dem_head.png'),
  two: getPublicPath('images/gun_violence/dem_head@2x.png'),
  a11y: 'a11y',
  loadingStyle: 'lazy',
};

const repHead = {
  one: getPublicPath('images/gun_violence/rep_head.png'),
  two: getPublicPath('images/gun_violence/rep_head@2x.png'),
  a11y: 'a11y',
  loadingStyle: 'lazy',
};


const gun_violence = {
  id: 'gun_violence',
  stats: {
    teaser: {
      year: '2014-2020',
      copy: 'In the last 6 years, there have been **86,743** gun violence deaths in the United States.[1]',
      image: {
        one: getPublicPath('images/gun_violence/red_top.png'),
        two: getPublicPath('images/gun_violence/red_top@2x.png'),
        a11y: 'a11y',
        loadingStyle: 'lazy',
      },
    },
    animations: [
      {
        animation: animRyanCox,
        number: '01',
        text: 'Ryan Cox',
      },
      {
        animation: animTaraGallagher,
        number: '02',
        text: 'Tara Gallagher',
      },
      {
        animation: animLaquitaBrown,
        number: '03',
        text: 'Laquita Brown',
      },
      {
        animation: animMichelleLanger,
        number: '04',
        text: 'Michelle Langer',
      },
      {
        animation: animAlexanderGusev,
        number: '05',
        text: 'Alexander Gusev',
      },
      {
        animation: animBobbyWilliams,
        number: '06',
        text: 'Bobby Williams',
      },
      {
        animation: animChristopherRapp,
        number: '07',
        text: 'Christopher Rapp',
      },
      {
        animation: animHerbertSnelling,
        number: '08',
        text: 'Herbert Snelling',
      },
      {
        animation: animJoshuaHardy,
        number: '09',
        text: 'Joshua Hardy',
      },
      {
        animation: animKatherineNixon,
        number: '10',
        text: 'Katherine Nixon',
      },
      {
        animation: animMaryLouiseGayle,
        number: '11',
        text: 'Mary Louise Gayle',
      },
      {
        animation: animRichardNettleton,
        number: '12',
        text: 'Richard Nettleton',
      },
    ],
    facts: {
      title: 'Gun Violence in Virginia',
      year: '2014-2020',
      stats: [
        {
          title: 'Lives Lost',
          figure: '2,345[2]',
        },
        {
          title: 'People Injured',
          figure: '5,590[2]',
        },
      ],
      footer: {
        text: '**12** of those lives were lost from a tragic mass shooting in Virginia Beach in May 2019.[3]',
        image: {
          one: getPublicPath('images/gun_violence/arrow_stats.png'),
          two: getPublicPath('images/gun_violence/arrow_stats@2x.png'),
          a11y: 'a11y',
          loadingStyle: 'lazy',
        },
      }
    },   
  },
  votes: {
    eyebrow: 'A Tragic Loss',
    title: 'On May 31st, a shooter opened fire at a {{RedOvalRake government facility}} in Virginia Beach using {{RedSingle high-capacity magazines}} and silencers.',
    leadText: 'Let’s see how an election in 2017, and just **one vote**, could have made a difference.',
    leadArrow: {
      one: getPublicPath('images/racial_justice/purple_down.png'),
      two: getPublicPath('images/racial_justice/purple_down@2x.png'),
      a11y: 'a11y',
      loadingStyle: 'lazy',
    },
    details: {
      issue: 'VA House of Delegates seat 94',
      candidates: [
        {
          title: 'David Yancey (R)',
          image: {
            one: getPublicPath('images/gun_violence/rep_head.png'),
            two: getPublicPath('images/gun_violence/rep_head@2x.png'),
            a11y: 'a11y',
            loadingStyle: 'lazy',
          },
        },
        {
          title: 'Shelly Simonds (D)',
          image: {
            one: getPublicPath('images/gun_violence/dem_head.png'),
            two: getPublicPath('images/gun_violence/dem_head@2x.png'),
            a11y: 'a11y',
            loadingStyle: 'lazy',
          },
        },
      ],
      timeline: [
        {
          when: 'Election Night',
          what: `On election night, there were 11,597 votes for Yancey <span><img src=${repHead.one} /></span> and 11,585 votes for Simonds <span><img src=${demHead.one} /></span>, Giving Yancey a 12 vote lead.`,
        },
        {
          when: 'Provisional Ballots',
          what: `Once provisional ballots were counted, Yancey’s <span><img src=${repHead.one} /></span> lead shrunk to 10 votes ahead of Simonds <span><img src=${demHead.one} /></span>.`,
        },
        {
          when: 'Recount',
          what: `Simonds <span><img src=${demHead.one} /></span> asked for a recount, which lead to her having just a 1 vote lead over Yancey <span><img src=${repHead.one} /></span>.`,
        },
        {
          when: 'Court Ruling',
          what: `Yancey <span><img src=${repHead.one} /></span> brought a ballot that had been discounted to a 3-judge court, and they ruled in Yancey’s favor, creating a tie between him and Simonds <span><img src=${demHead.one} /></span>.`,
        }
      ]
    },
    swoopArrow: {
      one: getPublicPath('images/gun_violence/red_curved_1.png'),
      two: getPublicPath('images/gun_violence/red_curved_1@2x.png'),
      a11y: 'a11y',
      loadingStyle: 'lazy',
    },
    winnerText: 'Winner of the {{RedOval random}} drawing tie-breaker:',
    winnerImage: {
      one: getPublicPath('images/gun_violence/candidate.png'),
      two: getPublicPath('images/gun_violence/candidate@2x.png'),
      a11y: 'a11y',
      loadingStyle: 'lazy',
    },
    winnerImageCredit: 'David Yancey for Delegate',
    winnerTape: {
      one: getPublicPath('images/gun_violence/candidate_tape.png'),
      two: getPublicPath('images/gun_violence/candidate_tape@2x.png'),
      a11y: 'a11y',
      loadingStyle: 'lazy',
    },
  },
  impact: {
    blocks: [
      {
        title: 'Majority Control:',
        copy: 'With Yancey (R) securing his seat, the Virginia House of Delegates now had 51 Republicans and 49 Democrats.',
        image: {
          one: getPublicPath('images/gun_violence/red_next_1.png'),
          two: getPublicPath('images/gun_violence/red_next_1@2x.png'),
          a11y: 'a11y',
          loadingStyle: 'lazy',
        },
      },
      {
        title: 'Committee Control:',
        copy: 'Most committees were now controlled by Republicans, including the Committee for Public Safety where legislation regarding gun violence would typically be introduced.',
        image: {
          one: getPublicPath('images/gun_violence/red_next_2.png'),
          two: getPublicPath('images/gun_violence/red_next_2@2x.png'),
          a11y: 'a11y',
          loadingStyle: 'lazy',
        },
      },
      {
        title: 'Subcommittee Control:',
        copy: 'The Subcommittee for Firearm Safety, which is part of the Committee for Public Safety, now consisted of 4 Republicans and 2 Democrats.',
        image: {
          one: getPublicPath('images/gun_violence/red_next_3.png'),
          two: getPublicPath('images/gun_violence/red_next_3@2x.png'),
          a11y: 'a11y',
          loadingStyle: 'lazy',
        },
      },
      {
        title: '2018 Legislative Session:',
        copy: 'Though dozens of gun safety bills were introduced in 2018, not one survived the Republican-controlled Subcommittee for Firearm Safety.',
        image: {
          one: getPublicPath('images/gun_violence/red_next_4.png'),
          two: getPublicPath('images/gun_violence/red_next_4@2x.png'),
          a11y: 'a11y',
          loadingStyle: 'lazy',
        },
      },
    ],
    swoopImage: {
      one: getPublicPath('images/gun_violence/red_next_5.png'),
      two: getPublicPath('images/gun_violence/red_next_5@2x.png'),
      a11y: 'a11y',
      loadingStyle: 'lazy',
    },
    image: {
      one: getPublicPath('images/gun_violence/statehouse.jpg'),
      two: getPublicPath('images/gun_violence/statehouse@2x.jpg'),
      a11y: 'a11y',
      loadingStyle: 'lazy',
    },
    imageCredit: 'Marie Hickman/Stone via Getty Images',
    title: 'Of the bills introduced, 11 sought to either {{RedRake ban}} the manufacture and sale of high-capacity magazines, allow {{RedOval government}} buildings to regulate the carrying of firearms, or remove firearms from persons posing substantial risk. All were voted {{RedX down}} in subcommittee.[4],[5-13]',
    reminder: {
      title: 'Reminder:',
      copy: 'The Virginia Beach shooting took place on government property and involved high-capacity magazines, occurring just over a year after those bills were defeated.',
    },
  },
  present: {
    title: 'Back to today',
    copy: [
      'We can’t know if the passage of any of these bills meant to prevent gun violence would have prevented the death of those 12 Virginians. But we do know that in one election just one vote ended up having a major impact on gun safety legislation.',
      'Because 2 years later, there was another election.',
    ],
    images: [
      {
        one: getPublicPath('images/red_stripe.png'),
        two: getPublicPath('images/red_stripe@2x.png'),
        a11y: 'a11y',
        loadingStyle: 'lazy',
        start: 0,
        stop: 0,
        year: 'In 2019',
        text: 'Simonds challenged Yancey, and won by 3,000 votes.',
      },
      {
        one: getPublicPath('images/white_stripe.png'),
        two: getPublicPath('images/white_stripe@2x.png'),
        a11y: 'a11y',
        loadingStyle: 'lazy',
        start: 0,
        stop: -25,
        year: false,
        text: 'Democrats gained a majority in the VA House, 55-45.',
      },
      {
        one: getPublicPath('images/blue_stripe.png'),
        two: getPublicPath('images/blue_stripe@2x.png'),
        a11y: 'a11y',
        loadingStyle: 'lazy',
        start: 0,
        stop: -50,
        year: 'In 2020',
        text: 'The VA House passed 8+ Gun Violence Prevention Bills.[14]',
      },
    ]
  },
  action: {
    title: 'Now What?',
    copy: 'The fight to prevent gun violence is not over and it needs your help. Here are some close races coming in November 2020 where the fight for gun violence prevention could be at stake.',
    image: {
      one: getPublicPath('images/gun_violence/red_now_what.png'),
      two: getPublicPath('images/gun_violence/red_now_what@2x.png'),
      a11y: 'a11y',
      loadingStyle: 'lazy',
    },
    ctas: [
      {
        text: 'Arizona Senate',
        href: 'https://ballotpedia.org/United_States_Senate_special_election_in_Arizona,_2020',
      },
      {
        text: 'Florida State Senate',
        href: 'https://ballotpedia.org/Florida_State_Senate_elections,_2020',
      },
      {
        text: 'North Carolina Senate',
        href: 'https://ballotpedia.org/United_States_Senate_election_in_North_Carolina,_2020',
      },
    ]
  },
  followingTopic: {
    title: 'See how just a few votes mattered to other issues:',
    href: '#racial_justice',
    image: {
      one: getPublicPath('images/racial_justice/footer_image.png'),
      two: getPublicPath('images/racial_justice/footer_image@2x.png'),
      a11y: '',
      loadingStyle: 'lazy',
    },
    imageCredit: 'Daniel Lean-Olivas/AFP via Getty Images',
    label: {
      one: getPublicPath('images/racial_justice/title_text.png'),
      two: getPublicPath('images/racial_justice/title_text@2x.png'),
      a11y: 'Racial Justice',
      loadingStyle: 'lazy',
    },
  },
  citations: [
    {
      text: 'Gun Violence Archive',
      href: 'https://www.gunviolencearchive.org/',
    },
    {
      text: 'Gun Violence Archive: Virginia',
      href: 'https://www.gunviolencearchive.org/congress/va',
    },
    {
      text: 'City of Virginia Beach: An Independent Review of The Tragic Events of May 31, 2019',
      href: 'https://www.vbgov.com/government/departments/city-auditors-office/Documents/Hillard%20Heintze%20Final%20Report%20for%20Virginia%20Beach%2011-13-2019.pdf',
    },
    {
      text: 'Politico: One-vote recount win gives Democrats tie in Virginia state House',
      href: 'https://www.politico.com/story/2017/12/19/democrats-tie-virginia-house-of-delegates-244598',
    },
    {
      text: 'HB41',
      href: 'https://lis.virginia.gov/cgi-bin/legp604.exe?181+sum+HB41',
    },
    {
      text: 'HB140',
      href: 'https://lis.virginia.gov/cgi-bin/legp604.exe?181+sum+HB140',
    },
    {
      text: 'HB198',
      href: 'https://lis.virginia.gov/cgi-bin/legp604.exe?181+sum+HB198',
    },
    {
      text: 'HB261',
      href: 'https://lis.virginia.gov/cgi-bin/legp604.exe?181+sum+HB261',
    },
    {
      text: 'HB273',
      href: 'https://lis.virginia.gov/cgi-bin/legp604.exe?181+sum+HB273',
    },
    {
      text: 'HB596',
      href: 'https://lis.virginia.gov/cgi-bin/legp604.exe?181+sum+HB596',
    },
    {
      text: 'HB597',
      href: 'https://lis.virginia.gov/cgi-bin/legp604.exe?181+sum+HB597',
    },
    {
      text: 'HB928',
      href: 'https://lis.virginia.gov/cgi-bin/legp604.exe?181+sum+HB928',
    },
    {
      text: 'HB1145',
      href: 'https://lis.virginia.gov/cgi-bin/legp604.exe?181+sum+HB1145',
    },
    {
      text: 'Washington Post: Virginia’s Democratically controlled House passes seven gun control measures',
      href: 'https://www.washingtonpost.com/local/virginia-politics/virginias-democratic-controlled-house-of-delegates-passes-seven-gun-control-measures/2020/01/30/cce67e54-4386-11ea-b503-2b077c436617_story.html',
    },
  ]
}

export default gun_violence;
