
import getPublicPath from '../../utils/getPublicPath';

import animJacobBlake from '../animations/P16_JB.json';
import animBreonnaTaylor from '../animations/P14_BT.json';
import animAhmaudArbery from '../animations/P13_AA.json';

// watch the filenames on these: they don't fit the two-initials pattern
import animRayshardBrooks from '../animations/P17_RS.json';
import animGeorgeFloyd from '../animations/P15_GL.json';

const demHead = {
  one: getPublicPath('images/racial_justice/dem_head.png'),
  two: getPublicPath('images/racial_justice/dem_head@2x.png'),
  a11y: 'a11y',
  loadingStyle: 'lazy',
};

const repHead = {
  one: getPublicPath('images/racial_justice/rep_head.png'),
  two: getPublicPath('images/racial_justice/rep_head@2x.png'),
  a11y: 'a11y',
  loadingStyle: 'lazy',
};


const racial_justice = {
  id: 'racial_justice',
  stats: {
    teaser: {
      year: 'In 2019',
      copy: 'Only **13%** of the U.S. population is Black, yet they are the victims of **22%** of all fatal police shootings and make up **34%** of the incarcerated population.[1]',
      image: {
        one: getPublicPath('images/racial_justice/purple_top.png'),
        two: getPublicPath('images/racial_justice/purple_top@2x.png'),
        a11y: 'a11y',
        loadingStyle: 'lazy',
      },
    },
    animations: [
      {
        animation: animJacobBlake,
        number: '01',
        text: 'Jacob Blake',
      },
      {
        animation: animRayshardBrooks,
        number: '02',
        text: 'Rayshard Brooks',
      },
      {
        animation: animGeorgeFloyd,
        number: '03',
        text: 'George Floyd',
      },
      {
        animation: animBreonnaTaylor,
        number: '04',
        text: 'Breonna Taylor',
      },
      {
        animation: animAhmaudArbery,
        number: '05',
        text: 'Ahmaud Arbery',
      },
    ],
    facts: {
      title: 'Racial Justice in Georgia',
      year: '2010-2015',
      stats: [
        {
          title: 'Fatal Police Shootings[2]',
          figure: '171',
        },
        {
          title: 'Police Shootings Gone to Trial[2]',
          figure: '0',
        },
      ],
      footer: {
        text: 'The lack of prosecution of police officers who shot citizens that were either unarmed or shot in the back points to an alarming pattern in the Georgia justice system.',
        image: {
          one: getPublicPath('images/racial_justice/arrow_stats.png'),
          two: getPublicPath('images/racial_justice/arrow_stats@2x.png'),
          a11y: 'a11y',
          loadingStyle: 'lazy',
        },
      }
    },   
  },
  votes: {
    eyebrow: 'A Tragic Loss in 2020',
    title: 'The deaths of Ahmaud Arbery and Rayshard Brooks, one {{PurpleOvalRake at the hands}} of vigilantes, the other by police, added to calls for {{PurpleSingle racial justice reform in}} Georgia.',
    leadText: 'Let’s see how an election in 2018, voter suppression, and just **a few votes**, may have made a difference.',
    leadArrow: {
      one: getPublicPath('images/racial_justice/purple_down.png'),
      two: getPublicPath('images/racial_justice/purple_down@2x.png'),
      a11y: 'a11y',
      loadingStyle: 'lazy',
    },
    details: {
      issue: 'Georgia Governor’s Race:',
      candidates: [
        {
          title: 'Brian Kemp (R)',
          image: {
            one: getPublicPath('images/racial_justice/rep_head.png'),
            two: getPublicPath('images/racial_justice/rep_head@2x.png'),
            a11y: 'a11y',
            loadingStyle: 'Brian Kemp Face',
          },
        },
        {
          title: 'vs. Stacey Abrams (D)',
          image: {
            one: getPublicPath('images/racial_justice/dem_head.png'),
            two: getPublicPath('images/racial_justice/dem_head@2x.png'),
            a11y: 'a11y',
            loadingStyle: 'Stacey Abrams Face',
          },
        },
      ],
      timeline: [
        {
          when: 'Voter Registration',
          what: `As Georgia’s Secretary of State, Kemp <span><img src=${repHead.one} /></span> cancelled the registrations of over 1.4 million voters from 2010 to 2018.<sup>[3]</sup>`,
        },
        {
          when: 'Polling Locations',
          what: `Since 2012, GA closed 214 polling locations, many in neighborhoods home to low-income or predominately Black people.<sup>[4]</sup>`,
        },
        {
          when: 'The campaigns',
          what: `During the race, Kemp <span><img src=${repHead.one} /></span> refused to step down as Secretary of State, effectively overseeing his own election against Abrams <span><img src=${demHead.one} /></span>.<sup>[5]</sup>`,
        },
        {
          when: 'Election night',
          what: `Kemp <span><img src=${repHead.one} /></span> won the election with 1,978,408 votes to Stacey Abrams’ <span><img src=${demHead.one} /></span> 1,923,685, a margin of less than 2% of the vote.`,
        }
      ]
    },
    swoopArrow: {
      one: getPublicPath('images/racial_justice/purple_curved_1.png'),
      two: getPublicPath('images/racial_justice/purple_curved_1@2x.png'),
      a11y: 'a11y',
      loadingStyle: 'lazy',
    },
    winnerText: 'Winner of the {{PurpleOval controversial}} election:',
    winnerImage: {
      one: getPublicPath('images/racial_justice/candidate.png'),
      two: getPublicPath('images/racial_justice/candidate@2x.png'),
      a11y: 'a11y',
      loadingStyle: 'lazy',
    },
    winnerImageCredit: 'Kevin C. Cox/Getty Images News via Getty Images',
    winnerTape: {
      one: getPublicPath('images/racial_justice/candidate_tape.png'),
      two: getPublicPath('images/racial_justice/candidate_tape@2x.png'),
      a11y: 'a11y',
      loadingStyle: 'lazy',
    },
  },
  impact: {
    blocks: [
      {
        title: 'Criminal Justice:',
        copy: 'While the previous governor had revamped GA’s justice system to steer nonviolent offenders towards rehabilitation and away from prison, Kemp sought to undo that work once he was in office.[6]',
        image: {
          one: getPublicPath('images/racial_justice/purple_next_1.png'),
          two: getPublicPath('images/racial_justice/purple_next_1@2x.png'),
          a11y: 'a11y',
          loadingStyle: 'lazy',
        },
      },
      {
        title: 'State Budgets:',
        copy: 'His first step came in January 2020 when he promised more resources for law enforcement and severe cuts to Georgia’s public defender system and accountability courts.[6]',
        image: {
          one: getPublicPath('images/racial_justice/purple_next_2.png'),
          two: getPublicPath('images/racial_justice/purple_next_2@2x.png'),
          a11y: 'a11y',
          loadingStyle: 'lazy',
        },
      },
      {
        title: 'Hate Crime Bill:',
        copy: 'Kemp agreed to sign Georgia’s first Hate Crime Bill, a bill that had been stalled in the GA house up until Arbery’s death, but only as part of a compromise to get a Police Protections bill passed as well.[7]',
        image: {
          one: getPublicPath('images/racial_justice/purple_next_3.png'),
          two: getPublicPath('images/racial_justice/purple_next_3@2x.png'),
          a11y: 'a11y',
          loadingStyle: 'lazy',
        },
      },
      {
        title: 'Police Protections:',
        copy: 'Kemp then signs the Police Protections bill to assert that “Georgia is a state that unapologetically backs the blue,” yet the bill itself actually added very few safety measures.',
        image: {
          one: getPublicPath('images/racial_justice/purple_next_4.png'),
          two: getPublicPath('images/racial_justice/purple_next_4@2x.png'),
          a11y: 'a11y',
          loadingStyle: 'lazy',
        },
      },
    ],
    swoopImage: {
      one: getPublicPath('images/racial_justice/purple_next_5.png'),
      two: getPublicPath('images/racial_justice/purple_next_5@2x.png'),
      a11y: 'a11y',
      loadingStyle: 'lazy',
    },
    image: {
      one: getPublicPath('images/racial_justice/protests.jpg'),
      two: getPublicPath('images/racial_justice/protests@2x.jpg'),
      a11y: 'a11y',
      loadingStyle: 'lazy',
    },
    imageCredit: 'Probal Rashid/LightRocket via Getty Images',
    title: 'After undergoing nearly a {{PurpleRake decade}} of bipartisan support to reduce spending on the prison system, changes to the {{PurpleOval criminal justice}} and policing system are being rolled back by Kemp, with measures in place that high-profile critics say exist to fuel the {{PurpleX criminalization}} of and violence against Black people.[8]',
    reminder: {
      title: 'Reminder:',
      copy: 'While we can’t know what would have happened if Abrams had won with a fair election, we do see that progress on the criminal justice system may have been more of a priority.',
    },
  },
  present: {
    title: 'Back to today',
    copy: [
      'While activists for racial justice have taken to the streets to demand changes from their local and federal leadership, many legislators have quietly blocked their progress.',
      'There are small systemic legislative decisions - like voter suppression, police unions, private prisons and corruption - where the power to make the change lies in the hands of who we elect.',
    ],
    images: [
      {
        one: getPublicPath('images/red_stripe.png'),
        two: getPublicPath('images/red_stripe@2x.png'),
        a11y: 'a11y',
        loadingStyle: 'lazy',
        start: 0,
        stop: 0,
        year: 'In 2020',
        text: 'Of the 33 Georgians killed by police, more than half were Black.[9]',
      },
      {
        one: getPublicPath('images/white_stripe.png'),
        two: getPublicPath('images/white_stripe@2x.png'),
        a11y: 'a11y',
        loadingStyle: 'lazy',
        start: 0,
        stop: -25,
        year: false,
        text: 'Poll locations keep being closed and voters deregistered.',
      },
      {
        one: getPublicPath('images/blue_stripe.png'),
        two: getPublicPath('images/blue_stripe@2x.png'),
        a11y: 'a11y',
        loadingStyle: 'lazy',
        start: 0,
        stop: -50,
        year: 'For 2021',
        text: '$1.1B of GA’s $2.2B budget is for its Dept of Corrections.[10]',
      },
    ]
  },
  action: {
    title: 'Now What?',
    copy: 'The fight to end racial injustice is not over and it needs your help. Here are some close races coming in November 2020 where the fight for equality and justice could be at stake.',
    image: {
      one: getPublicPath('images/racial_justice/purple_now_what.png'),
      two: getPublicPath('images/racial_justice/purple_now_what@2x.png'),
      a11y: 'a11y',
      loadingStyle: 'lazy',
    },
    ctas: [
      {
        text: 'LA District Attorney',
        href: 'https://ballotpedia.org/Municipal_elections_in_Los_Angeles_County,_California_(2020)#District_Attorney',
      },
      {
        text: 'Minnesota State Senate',
        href: 'https://ballotpedia.org/Minnesota_State_Senate_elections,_2020',
      },
      {
        text: 'South Carolina Senate',
        href: 'https://ballotpedia.org/United_States_Senate_election_in_South_Carolina,_2020',
      },
    ]
  },
  followingTopic: {
    title: 'See how just a few votes mattered to other issues:',
    href: '#climate_crisis',
    image: {
      one: getPublicPath('images/climate_crisis/footer_image.png'),
      two: getPublicPath('images/climate_crisis/footer_image@2x.png'),
      a11y: '',
      loadingStyle: 'lazy',
    },
    imageCredit: 'MARK RALSTON/AFP via Getty Images',
    label: {
      one: getPublicPath('images/climate_crisis/title_text.png'),
      two: getPublicPath('images/climate_crisis/title_text@2x.png'),
      a11y: 'Climate Crisis',
      loadingStyle: 'lazy',
    },
  },
  citations: [
    {
      text: 'NAACP: Criminal Justice Fact Sheet',
      href: 'https://www.naacp.org/criminal-justice-fact-sheet/',
    },
    {
      text: 'The Atlanta Journal-Constitution: 171 SHOT DEAD, ZERO PROSECUTED',
      href: 'https://investigations.ajc.com/overtheline/prosecuted/',
    },
    {
      text: 'The Atlanta Journal-Constitution: Georgia cancels fewer voter registrations after surge last year',
      href: 'https://www.ajc.com/news/local-govt--politics/georgia-cancels-fewer-voter-registrations-after-surge-last-year/fqT1bcSzGu33UEpTMDzMVK/',
    },
    {
      text: 'The Atlanta Journal-Constitution: Voting precincts closed across Georgia since election oversight lifted',
      href: 'https://www.ajc.com/news/state--regional-govt--politics/voting-precincts-closed-across-georgia-since-election-oversight-lifted/bBkHxptlim0Gp9pKu7dfrN/',
    },
    {
      text: 'The Atlanta Journal-Constitution: Kemp rejects Democratic calls to quit as Georgia’s top voting official',
      href: 'https://www.ajc.com/news/state--regional-govt--politics/kemp-rejects-democratic-calls-quit-georgia-top-voting-official/fxxMPZLxJdBSbCUIyP29XN/',
    },
    {
      text: 'The Atlanta Journal-Constitution: Kemp pursues a new criminal justice policy, unnerving critics',
      href: 'https://www.ajc.com/news/state--regional-govt--politics/kemp-pursues-new-criminal-justice-policy-unnerving-critics/kjbvlgLsPWnDE2RrWROM5L/',
    },
    {
      text: 'TIME: Georgia Approves Hate Crimes Law, But Critics Say it Could Be Tainted By Pending Bill that Adds Protections for Police',
      href: 'https://time.com/5857282/georgia-hate-crimes/',
    },
    {
      text: 'Georgia NAACP: Governor Kemp announces Hate Crimes Bill Ceremony for 2pm at State Capitol',
      href: 'https://www.naacpga.org/amp/governor-kemp-announces-hate-crimes-bill-ceremony-for-2pm-at-state-capitol',
    },
    {
      text: 'Mapping Police Violence',
      href: 'https://mappingpoliceviolence.org/',
    },
    {
      text: 'Georgia Budget & Policy Institute: Georgia Criminal Legal System Budget Primer for State Fiscal Year 2021',
      href: 'https://gbpi.org/georgia-criminal-legal-system-budget-primer-for-state-fiscal-year-2021/',
    },
  ]
}

export default racial_justice;