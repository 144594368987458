import React, {useEffect, useState} from 'react';
import styled from 'styled-components/macro';
import Lottie from 'react-lottie-player'
import Image from '../ui/Image/Image'
import { useInView } from 'react-intersection-observer';
import { SmallBody } from '../Type/Type';
import { decoratedText } from "../Type/DecoratedText";

import emptyAnimation from "./animations/empty.json";

const Wrapper = styled.div`
  max-width: 1440px;
  padding-bottom: 100px;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (${props => props.theme.breakpoints.desktop}) {
    margin: 0 100px;
  }
`;

const Teaser = styled.div`
  max-width: 250px;
  padding: 100px 0 120px 25px;
  align-self: flex-start;

  @media (${props => props.theme.breakpoints.desktop}) {
    padding: 500px 0 240px 50px;
  }

  span {
    border-bottom: 1px solid ${props => props.theme.colors.dark};
    font-family: ${props => props.theme.fonts.nimbus};
    padding-bottom: 5px;
  }

  p {
    margin-top: 36px;
    font-family: ${props => props.theme.fonts.nimbus};
  }

  img {
    margin-top: 50px;
    margin-left: 20px;
    width: 80px;

    @media (${props => props.theme.breakpoints.desktop}) {
      margin-top: 80px;
      margin-left: 50px;
      width: 120px;
    }
  }
`
const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  align-items: center;
  width: 100%;

  @media (${props => props.theme.breakpoints.desktop}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  }
`;
const AnimationContainer = styled.div`
  width: 90%;

  @media (${props => props.theme.breakpoints.desktop}) {
    width: 50%;
  }
`;

const AnimationFooter = styled.div`
  border-top: 1px solid ${props => props.theme.colors.dark};
  display: flex;
  justify-content: space-between;

  span {
    margin-top: 16px;
    text-transform: uppercase;
    line-height: 18px;
  }
`;

const StatsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (${props => props.theme.breakpoints.desktop}) {
    width: 40%;
    align-items: flex-start;
  }
`;

const StatsHeader = styled.header`
  border-top: 1px solid ${props => props.theme.colors.dark};
  border-bottom: 1px solid ${props => props.theme.colors.dark};
  display: flex;
  justify-content: space-between;
  width: 100%;

  span {
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 0 12px;
    padding-left: 12px;
    padding-right: 12px;
    text-transform: uppercase;
    line-height: 18px;

    @media (${props => props.theme.breakpoints.desktop}) {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

const StatsContent = styled.div`

`;

const StatsCopy = styled.div`
  align-self: flex-start;
  margin: 60px 0;

  h1 {
    margin: 0;
    padding: 0;
    font-family: ${props => props.theme.fonts.nimbus};
    font-weight: 700;
    font-size: 40px;
    text-align: center;

    @media (${props => props.theme.breakpoints.desktop}) {
      font-size: 50px;
      text-align: left;
    }
  }
  h2 {
    font-weight: 300;
    font-family: ${props => props.theme.fonts.ivy};
    font-style: italic;
    font-size: 40px;
    margin: 0px;
    text-align: center;

    @media (${props => props.theme.breakpoints.desktop}) {
      text-align: left;
      margin: 0px 0 0 64px;
      font-size: 50px;
    }
`;

const StatsFooter = styled.div`

  margin-top: auto;
  padding-bottom: 50px;
  font-size: 26px;
  line-height: 30px;
  font-family: ${props => props.theme.fonts.nimbus};
  text-align: center;
  width: 100%;
  max-width: 400px;
  align-self: center;

  @media (${props => props.theme.breakpoints.desktop}) {
    text-align: left;
    width: 100%;
    padding-bottom: 0px;
    align-self: flex-end;
    max-width: 100%;
  }

  p {
    margin: 0 12px;
    @media (${props => props.theme.breakpoints.desktop}) {
      margin: 0;
    }
  }

  span {
    font-size: 30px;
    font-weight: 600;
    font-family: ${props => props.theme.fonts.ivy};
    font-style: italic;
  }

  img {
    margin-right: 25px;
  }
`;

const Stats = ({content}) => {

  const [isActive, setIsActive] = useState(false);

  const [animationGroup, setAnimationGroup] = useState(content.animations);
  const [animationIndex, setAnimationIndex] = useState(0);
  const [animation, setAnimation] = useState(animationGroup[animationIndex]);
  const [isLoop, setIsLoop] = useState(false);

  const [sectionRef, isSectionInView] = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  const progressAnimation = () => {
    setAnimationIndex((animationIndex + 1) % animationGroup.length);
  }

  useEffect(() => {
    setIsActive(isSectionInView);
    if (isSectionInView) {
      setAnimationIndex(0);
    }
  }, [isSectionInView]);

  useEffect(() => {
    setAnimation(animationGroup[animationIndex]);
  }, [animationGroup, animationIndex]);

  useEffect(() => {
    setAnimationGroup(content.animations);
    setAnimationIndex(0);
  }, [content]);

  return (
    <Wrapper>

      <Teaser>
        <span>{content.teaser.year}</span>
        <p>{decoratedText(content.teaser.copy)}</p>
        <Image content={content.teaser.image} />
      </Teaser>

    <MainContainer>

      <AnimationContainer ref={sectionRef}>
        <Lottie 
          play={isActive}
          loop={isLoop}
          animationData={isActive ? animation.animation : emptyAnimation}
          onComplete={progressAnimation}
        />
        <AnimationFooter>
          <SmallBody>({animation.number})</SmallBody>
          <SmallBody>{animation.text}</SmallBody>
        </AnimationFooter>
      </AnimationContainer>

      <StatsContainer>
        <StatsHeader>
          <SmallBody>{content.facts.title}</SmallBody>
          <SmallBody>{content.facts.year}</SmallBody>
        </StatsHeader>
        <StatsContent>
          {content.facts.stats.map((stat, index) => 
            <StatsCopy key={index}>
              <h1>
                {decoratedText(stat.title)}
              </h1>
              <h2>
                {decoratedText(stat.figure)}
              </h2>
            </StatsCopy>
          )}
        </StatsContent>
        <StatsFooter>
          <p>
            <Image content={content.facts.footer.image} />
            {decoratedText(content.facts.footer.text)}
          </p>
        </StatsFooter>
      </StatsContainer>
    </MainContainer>


    </Wrapper>
  )
}

export default Stats;